// Upload
export const UPLOAD_LIMITATION_LINK = `https://aka.ms/vi-uploading-limitations`;
export const UPLOAD_FORMAT_LINK = `https://aka.ms/upload-standard-formats`;
export const UPLOAD_FORMAT_LINK_AMSLESS = `https://aka.ms/vi-supported-formats-codecs`;
export const MULTI_LANGUAGE_LINK = 'https://aka.ms/vi-multi-language-identification';
export const AZURE_US_PORTAL_LINK = 'https://aka.ms/vi-api-portal-us';
export const UPLOAD_INDEXING_PRESET = 'https://aka.ms/vi-indexing-preset';
export const RAI_LEARN_MORE = 'https://aka.ms/vi-sensitive-ai';
export const LOGO_API_LINK = 'https://aka.ms/vi-textual-logo';

// Media
export const MEDIA_STREAMING_PRESET = `https://aka.ms/vi-indexing-streaming-preset`;

// Create new account
export const VIDEO_INDEXER_PRICING_LINK = 'https://aka.ms/vi-pricing';

export const GET_STARTED_WITH_AZURE_FREE = 'https://azure.microsoft.com/en-us/free/';

export const CONNECT_TO_AZURE_DOCUMENTATION = 'https://aka.ms/vi-connect-to-azure';

export const VIDEO_INDEXER_ACCOUNT_TYPES = 'https://aka.ms/vi-account-update';

export const VIDEO_INDEXER_CLASSIC_UPDATE = 'https://aka.ms/vi-classic-account-update';

// Settings
export const REPAIR_ACCOUNT_LINK = `https://aka.ms/vi-repair-account`;

export const INCREASE_RESERVED_UNITS = 'https://aka.ms/vi-increase-reserved-units';

// General
export const ONLINE_SERVICES_TERMS = 'https://aka.ms/vi-online-services-terms';

export const VI_DEV_SUPPORT = 'https://aka.ms/visupport';

export const VI_SURVEY = 'https://aka.ms/vi-portal-survey';

export const VI_SWITCH_DIRECTORY_LEARN_MORE = 'https://aka.ms/vi-switch-directory-learn-more';

// Customization
export const CUSTOMIZATION_PEOPLE_LINK = 'https://aka.ms/vi-customize-person-model-with-api';

export const LANGUAGE_CUSTOMIZATION_DOCUMENTATION = 'https://aka.ms/language-customisation';

export const CUSTOMIZATION_VISION_LINK = 'https://aka.ms/custom-vision-link';

export const CUSTOMIZATION_VISION_FF_LINK = 'https://aka.ms/custom-vision-us-link';

export const CUSTOMIZE_PEOPLE_MODEL = 'https://aka.ms/vi-customize-person-model-overview';

export const SPEECH_DATASET_PLAIN_TEXT = 'https://aka.ms/vi-speech-dataset-plain-text';

export const SPEECH_DATASET_PRONUNCIATION = 'https://aka.ms/vi-speech-dataset-pronunciation';

// US Government Onboarding
export const US_GOV_ONBOARDING = 'https://aka.ms/vi-usgov';

// Login
export const LINKEDIN_FACEBOOK_DEPRECATION_NOTE_LINK = 'https://aka.ms/vi-linkedIn-facebook-deprecation-note';

// Privacy statement
export const PAID_PRIVACY_SETTING = 'https://aka.ms/vi-paid-ps';
export const TRIAL_PRIVACY_SETTING = 'https://aka.ms/vi-privacy-statement';

// Service agreement
export const PAID_SERVICE_AGREEMENT = 'https://aka.ms/vi-paid-sa';
export const TRIAL_SERVICE_AGREEMENT = 'https://aka.ms/vi-tou';

// PORTAL
export const US_PORTAL = 'https://portal.azure.us';
export const PORTAL = 'https://portal.azure.com';

// Support
export const FF_SUPPORT = 'https://aka.ms/ff-support';
export const PAID_SUPPORT = 'https://aka.ms/visupport-azure';
export const TRIAL_SUPPORT = 'https://aka.ms/portal-stack-overflow';

// Video indexer
export const VIDEO_INDEXER = 'https://aka.ms/vi-portal-link';
export const VIDEO_INDEXER_FF = 'https://aka.ms/vi-portal-link-us';
export const VIDEO_INDEXER_LANDING_PAGE = 'https://aka.ms/vi-landing-page';

// Insights
export const VIDEO_INSIGHTS_OVERVIEW = 'https://aka.ms/vi-insights-overview';

// Face Recognition Form
export const FACE_RECOGNITION_FORM = 'https://aka.ms/facerecognition';

// Face Gate AVAM doc
export const FACE_GATE_DOC = 'https://aka.ms/vi-limited-faces';

// Bing search
export const BING_SEARCH = 'https://www.bing.com/search';

// Face Gate popup
export const VI_LIMITED_ACCESS_FEATURES_DOC = 'https://aka.ms/vi-limited-access-features';
export const AZURE_LEGAL_TERMS = 'https://aka.ms/vi-azure-legal-terms';
export const CUSTOMER_SOLUTION = 'https://aka.ms/vi-customer-solution';
export const lIMITED_ACCESS_SERVICES_TERMS = 'https://aka.ms/vi-limited-access-services';
export const RESTRICTION_ON_US_POLICE = 'https://aka.ms/vi-restrictions-us-police';
export const ONLINE_SERVICES_DPA = 'https://aka.ms/vi-online-services-dpa';
export const REPORT_SENSITIVE_USE = 'https://aka.ms/vi-report-sensitive-use';

// AMS retirement
export const AMS_RETIREMENT_LINK = 'https://aka.ms/ams-retirement';
export const AMS_RETIREMENT_ACCOUNT_UPGRADE = 'https://aka.ms/vi-upgrade-arm';
export const AMS_RETIREMENT_ACCOUNT_UPDATE = 'https://aka.ms/vi-account-update-guide';

// Permissions
export const MANAGE_ACCOUNT_ACCESS = 'https://aka.ms/vi-manage-account-access';

// Textual summary
export const TEXTUAL_SUMMARY_DEPLOYMENTS = 'https://aka.ms/VI_AOAI_supported_models';
export const TEXTUAL_SUMMARY_FLAVORS = 'https://aka.ms/VI_Textual_Summary_Flavors';
export const TEXTUAL_SUMMARY_FILTERED_CONTENT = 'https://aka.ms/VI_AOAI_content_filters';
