<div class="container">
  <div class="container-form">
    <!--URL-->
    <div class="row-form"
      *ngIf="isURLMode">
      <div class="col-form">
        <div class="url-container">
          <vi-ui-input class="url-input input-form"
            [label]="resources?.UploadEnterURLLabel"
            [value]="uploadURL"
            [attr.aria-labelledby]="'UploadEnterURLLabel'"
            [placeholder]="urlPlaceholder"
            [icon]="urlIcon"
            (inputChanged)="onChangeURL($event)"
            [errorMessage]='urlValidationErrorMessage'
            [disabled]="maxFilesReached || isUrlValidationLoading"></vi-ui-input>
          <div class="add-files-hover-wrapper"
            [appAccessibilityContentOnHoverHandler]
            (contentOnHover)="onAddFilesBtnHover($event)">
            <app-vi-action-button class="add-url-button"
              #AddURLButton
              [type]="ActionButtonType.SECONDARY"
              [action]="addURLAction"
              [minWidth]="ADD_URL_BUTTON_MIN_WIDTH"
              (actionClick)="onAddURLClicked()"
              [isDisabled]="!uploadURL || !isURLValid || maxFilesReached || isUrlValidationLoading">
            </app-vi-action-button>
            <!-- Max file limit reached -->
            <ng-container [ngTemplateOutlet]="filesLimitReachedNotification"
              [ngTemplateOutletContext]="{ button: addURLButton, direction: Direction.LEFT }"></ng-container>
          </div>
        </div>
        <vi-ui-link class='upload-url-link'
          [ngClass]="{ hide: urlValidationErrorMessage }"
          [href]="limitationsLink"
          [title]="resources?.UploadGuidelinesLink2"
          [role]="'link'"
          [text]="resources?.UploadGuidelinesLink2">
        </vi-ui-link>
      </div>
    </div>
    <!--Single File Name-->
    <div class="row-form"
      *ngIf="showSingleFileName">
      <div class="col-form">
        <vi-indexing-file-name-input class="name input-form"
          [ngClass]="{ 'file-error': !!uploadFileErrorMessage }"
          [file]="file"
          [label]="resources?.IndexingFileNameLabel"
          [disabled]="isFileNameDisabled"
          [attr.disabled]="isFileNameDisabled"
          [attr.aria-disabled]="isFileNameDisabled"
          [errorMessage]="uploadFileErrorMessage"
          [attr.aria-labelledby]="'IndexingFileNameLabel'"
          (nameChanged)="onChangeFileName($event)">
        </vi-indexing-file-name-input>
      </div>
    </div>
    <ng-container *ngIf="showAddFilesBtnSingleFileMode">
      <ng-container *ngTemplateOutlet="addFilesButtonTemplate">
      </ng-container>
    </ng-container>
    <!--Multi Files-->
    <div class="row-form"
      *ngIf="showFilesContainer">
      <div class="col-form">
        <vi-ui-label class="label-form"
          [value]="resources?.IndexingMultiFilesLabel"
          [for]="filesContainerId"></vi-ui-label><span class="label-desc">{{ resources?.IndexingMultiFilesLabelDesc }}</span>
        <vi-ui-tag-container class="files-container"
          [tags]="fileTags"
          (tagRemoved)="onFileRemoved($event)"
          [attr.id]="filesContainerId">
        </vi-ui-tag-container>
      </div>
    </div>
    <ng-container *ngIf="showAddFilesBtnMultiFileMode">
      <ng-container *ngTemplateOutlet="addFilesButtonTemplate">
      </ng-container>
    </ng-container>
    <div *ngIf="showAdaptiveBitrateDisclaimer"
      class="row-form">
      <vi-ui-alert-message [type]="AMS_DEPRECATION_MESSAGE_TYPE"
        [content]="adaptiveBitrateDisclaimerMessage"
        [linkText]="adaptiveBitrateDisclaimerLinkMessage"
        [linkPath]="amsDeprecationLink">
      </vi-ui-alert-message>
    </div>
    <div class="row-form privacy-streaming-row"
      *viShowIfEdge="false">
      <!--Privacy-->
      <div class="col-form">
        <vi-ui-label class="label-form"
          [disabled]="disablePrivacy"
          [value]="resources?.UploadPrivacyLabel"
          [for]="indexingPrivacyId"
          [id]="indexingPrivacyLabelId"></vi-ui-label>
        <vi-fluent-ui-select [id]="indexingPrivacyId"
          [action]="privacyAction"
          [(selectedAction)]="selectedPrivacy"
          [actionList]="privacyOptions"
          [ariaLabelledBy]="indexingPrivacyLabelId"
          [isDisabled]="isDisabled || disablePrivacy"
          (selectedActionChange)="onSelectPrivacy($event)">
        </vi-fluent-ui-select>
      </div>
      <div class="col-form">
        <vi-ui-label class="label-form"
          [value]="resources?.UploadStreaming"
          [disabled]="isDisabled"
          [for]="indexingStreamingId"
          [id]="indexingStreamingLabelId"></vi-ui-label>
        <!--Streaming Quality-->
        <vi-fluent-ui-select [id]="indexingStreamingId"
          [action]="streamAction"
          [(selectedAction)]="selectedStreaming"
          [actionList]="streamOptions"
          [ariaLabelledBy]="indexingStreamingLabelId"
          [isDisabled]="isDisabled"
          (selectedActionChange)="onSelectStreaming($event)">
        </vi-fluent-ui-select>
      </div>
    </div>
    <div class="row-form">
      <!--Language-->
      <vi-indexing-language [isDisabled]="isDisabled"
        (languageIdLinkClick)="onLanguageIdLinkClicked()"
        (managedLanguageModelsClick)="onManageLanguageClicked()"
        (managedSpeechModelsClick)="onSpeechModelsClicked()">
      </vi-indexing-language>
    </div>
  </div>
  <ng-template #addFilesButtonTemplate>
    <div class="row-form add-files-container">
      <div class="col-form">
        <div class="add-files-hover-wrapper"
          [appAccessibilityContentOnHoverHandler]
          (contentOnHover)="onAddFilesBtnHover($event)">
          <app-vi-action-button #AddFilesButton
            class="add-files-button"
            [type]="ActionButtonType.SECONDARY"
            [action]="addFilesAction"
            [isDisabled]="maxFilesReached"
            [minWidth]="ADD_FILES_BUTTON_MIN_WIDTH"
            (actionClick)="uploadChooseFiles(fileInput)">
          </app-vi-action-button>
          <!-- Max file limit reached -->
          <ng-container [ngTemplateOutlet]="filesLimitReachedNotification"
            [ngTemplateOutletContext]="{ button: addFilesButton, direction: Direction.RIGHT }"></ng-container>
        </div>
        <input #fileInput
          type="file"
          name="fileInput"
          [accept]="filesType"
          multiple
          (change)="onFileBrowse($event)" />
      </div>
    </div>
  </ng-template>
  <!-- Max file limit reached notification -->
  <ng-template #filesLimitReachedNotification
    let-targetButton="button"
    let-direction="direction">
    <app-vi-notification-tooltip *ngIf="showAddFilesBtnTooltip"
      [targetElement]="targetButton"
      [direction]="direction"
      [showArrow]="true"
      [centerArrow]="true"
      [addAnimation]="false"
      [notificationText]="resources?.IndexingMaxFilesLimitTooltip">
    </app-vi-notification-tooltip>
  </ng-template>
</div>