import { ChangeDetectionStrategy, Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';

import { Subject, takeUntil } from 'rxjs';

import { FocusableComponent } from '@common/modules/accessibility/focusable/focusable.component';
import { ActionButtonType } from '@common/modules/shared/components/action-button/interfaces';
import { UPLOAD_FORMAT_LINK, UPLOAD_LIMITATION_LINK, UPLOAD_FORMAT_LINK_AMSLESS } from '@common/modules/shared/links';
import { TranslateHelperService } from '@common/modules/translation/services/translate-helper.service';

import { CoreStoreService } from '../../../../core/services/core-store.service';
import { resources } from './resources';
import { backToUpload } from './actions';

@Component({
  selector: 'vi-upload-guide',
  templateUrl: './upload-guide.component.html',
  styleUrls: ['./upload-guide.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UploadGuideComponent extends FocusableComponent implements OnInit, OnDestroy {
  @Output() public backClicked = new EventEmitter<void>();
  public resources = resources;
  public ActionButtonType = ActionButtonType;
  public backAction = backToUpload;

  public formatsLink = UPLOAD_FORMAT_LINK;
  public readonly limitationsLink = UPLOAD_LIMITATION_LINK;

  private destroy$ = new Subject<void>();

  constructor(private translate: TranslateHelperService, private coreStore: CoreStoreService) {
    super();
    this.translate.translateResourcesInstant(this.resources);
    this.backAction.title = this.resources.UploadGuideBackButton;
  }

  public ngOnInit() {
    this.coreStore.isAmslessAccount$.pipe(takeUntil(this.destroy$)).subscribe(isAmslessAccount => {
      this.formatsLink = isAmslessAccount ? UPLOAD_FORMAT_LINK_AMSLESS : UPLOAD_FORMAT_LINK;
    });
  }

  public ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public backBtnClicked() {
    this.backClicked.emit();
  }

  public openLink(url) {
    window.open(url, '_blank');
  }
}
