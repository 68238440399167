export const resources = {
  UploadServiceDefaultName: '',
  AppSettingsPrivate: '',
  AppSettingsPublic: '',
  UploadPrivacyLabel: '',
  UploadServiceSingleBiterate: '',
  UploadServiceAdaptiveBiterate: '',
  UploadServiceNoStreaming: '',
  UploadLogoGroupOption: '',
  UploadStreaming: '',
  IndexingPresetLabel: '',
  UploadVideoSourceLanguage: '',
  IndexingPresetBasicVideoAudioLabel: '',
  IndexingPresetBasicVideoAudioDetails: '',
  IndexingPresetStandardVideoAudioLabel: '',
  IndexingPresetStandardVideoAudioDetails: '',
  IndexingPresetAdvancedVideoAudioLabel: '',
  IndexingPresetAdvancedVideoAudioDetails: '',
  IndexingPresetBasicVideoLabel: '',
  IndexingPresetBasicVideoDetails: '',
  IndexingPresetStandardVideoLabel: '',
  IndexingPresetStandardVideoDetails: '',
  IndexingPresetAdvancedVideoLabel: '',
  IndexingPresetAdvancedVideoDetails: '',
  IndexingPresetBasicAudioLabel: '',
  IndexingPresetBasicAudioDetails: '',
  IndexingPresetStandardAudioLabel: '',
  IndexingPresetStandardAudioDetails: '',
  IndexingPresetAdvancedAudioLabel: '',
  IndexingPresetAdvancedAudioDetails: '',
  IndexingPresetTooltipText: '',
  IndexingPresetDetailsLabel: '',
  IndexingPresetLearnMoreLinkText: '',
  IndexingPresetExcludeRAICheckboxLabel: '',
  IndexingPresetExcludeRAITooltipText: '',
  IndexingPresetExcludeRAIDescription: '',
  IndexingPresetRAILinkText: '',
  UploadPeopleModelOption: '',
  UploadPeopleModel: '',
  BrandsModel: '',
  ExcludeRAI: '',
  IncludedModelsLabel: '',
  ExcludedModelsLabel: '',
  IndexingSummaryFailedTitle: '',
  InsightsTranslationErrorGetIndexDescription: '',
  IndexedBy: '',
  GalleryItemLastModified: '',
  ReindexModalVideoSourceLanguage: '',
  GalleryListItemMediaThumbnail: '',
  IndexingSummaryLoadingPlaceholder: ''
};
