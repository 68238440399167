import { PrivacyMode } from '@common/modules/core/interfaces';
import { IAction, StreamingPreset } from '@common/modules/shared/interfaces';
import { UIActionType } from '@common/modules/insights/interfaces';

import { UIIndexingActionType } from '../interfaces';

export const addURLAction: IAction = {
  title: '',
  type: UIActionType.ADD,
  value: '',
  selected: false,
  selectable: false,
  key: 'Add',
  id: 'addURLButton'
};

export const addFilesAction: IAction = {
  title: '',
  type: UIActionType.ADD,
  value: '',
  selected: false,
  selectable: false,
  key: 'IndexingAddFilesButton',
  id: 'addFilesButton'
};

export const privacyAction: IAction = {
  title: '',
  type: UIActionType.PRIVACY,
  value: UIActionType.PRIVACY,
  selected: false,
  selectable: false,
  id: 'privacy'
};

export const privacyOptions: IAction[] = [
  {
    key: 'AppSettingsPrivate',
    title: '',
    selected: false,
    type: UIActionType.PRIVACY_SET_PRIVATE,
    value: PrivacyMode.PRIVATE,
    selectable: true,
    id: 'PRIVATE'
  },
  {
    key: 'AppSettingsPublic',
    title: '',
    selected: false,
    type: UIActionType.PRIVACY_SET_PUBLIC,
    value: PrivacyMode.PUBLIC,
    selectable: true,
    id: 'PUBLIC'
  }
];

export const streamAction: IAction = {
  title: '',
  type: UIIndexingActionType.STREAM,
  value: UIIndexingActionType.STREAM,
  selected: false,
  selectable: false,
  id: 'stream'
};

export const streamOptions: IAction[] = [
  {
    key: 'UploadServiceSingleBiterate',
    title: '',
    selected: false,
    type: UIIndexingActionType.STREAM_SINGLE,
    value: StreamingPreset.SingleBitrate,
    selectable: true,
    id: 'single'
  },
  {
    key: 'UploadServiceAdaptiveBiterate',
    title: '',
    selected: false,
    type: UIIndexingActionType.STREAM_ADAPTIVE,
    value: StreamingPreset.AdaptiveBitrate,
    selectable: true,
    id: 'adaptive'
  },
  {
    key: 'UploadServiceNoStreaming',
    title: '',
    selected: false,
    type: UIIndexingActionType.STREAM_NO_STREAM,
    value: StreamingPreset.NoStreaming,
    selectable: true,
    id: 'noStream'
  }
];
