import { createSelector } from '@ngrx/store';

import { envTypes } from '@common/modules/api/interfaces';
import { AccountResourceType } from '@common/modules/auth/interfaces';
import { locationsUS } from '@common/modules/api/services/locations-us';
import { APIErrors } from '@common/modules/core/services/toast/errors';

import { FACE_GATE_DATE, FACE_GATE_GRACE_END_DATE } from '../faceGatedDates';
import { highRiskRegions } from '../high-risk-regions';
import { selectCoreState } from '../reducers';
import * as fromAccounts from '../reducers/accounts.reducer';

export const getAccountsState = createSelector(selectCoreState, state => state.accounts);

export const selectAllAccounts = createSelector(getAccountsState, state => state.entities);

export const selectCurrentAccountId = createSelector(getAccountsState, state => state.selectedAccountId);

export const selectedAccountContract = createSelector(getAccountsState, state => state.selectedAccountContract);

export const selectedAccountLoaded = createSelector(getAccountsState, state => state.selectedAccountLoaded);

export const accountSaving = createSelector(getAccountsState, state => state.saving);

export const isAccountDeleteInProgress = createSelector(getAccountsState, state => state.deleteInProgress);

export const accountError = createSelector(getAccountsState, state => state.error);

export const isAccountFaceGated = createSelector(selectedAccountContract, accountContract => {
  if (accountContract) {
    const accountCreateTime = new Date(accountContract?.createTime);
    const today = new Date();

    const isNewAccount = accountCreateTime.getTime() > FACE_GATE_DATE.getTime();
    const isAccountInGraceDate = today.getTime() < FACE_GATE_GRACE_END_DATE.getTime();
    return isNewAccount || !isAccountInGraceDate;
  }
});

export const isAccountLimitedWithFaces = createSelector(selectedAccountContract, isAccountFaceGated, (accountContract, isFaceGated) => {
  // If account have face gating and didn't sign form - should be limited with faces
  return isFaceGated && !accountContract?.limitedAccessFeatures?.isFaceIdentificationEnabled;
});

export const showFaceGateWarning = createSelector(selectedAccountContract, accountContract => {
  return !accountContract?.limitedAccessFeatures?.isFaceIdentificationEnabled;
});

export const isAccountInHighRiskRegion = createSelector(selectedAccountContract, accountContract => {
  return (
    accountContract?.accountType === envTypes.PAID &&
    highRiskRegions.includes(accountContract.location) &&
    !accountContract?.limitedAccessFeatures?.isFaceDetectionEnabled
  );
});

export const selectCurrentAccount = createSelector(
  selectAllAccounts,
  getAccountsState,
  (accountEntities, state) => accountEntities[state.selectedAccountId]
);

export const selectAccountType = createSelector(selectCurrentAccount, state => state?.accountType);

export const mapUIAccounts = createSelector(selectAllAccounts, accounts => {
  return Object.values(accounts).map(account => ({
    id: account.id,
    location: account.location,
    name: account.name,
    accountType: account.accountType,
    resourceType: account.resourceType
  }));
});

export const selectAccountsCount = createSelector(mapUIAccounts, accounts => accounts?.length);

export const selectArmAccounts = createSelector(mapUIAccounts, mapAccounts =>
  mapAccounts.filter(account => account.resourceType === AccountResourceType.ARM)
);

export const selectArmAccountsFromLocation = (location: string) =>
  createSelector(selectArmAccounts, armAccounts => armAccounts.filter(account => account.location === location));

export const selectUIAccounts = createSelector(mapUIAccounts, mapAccounts => {
  const trialAccounts = mapAccounts
    .filter(account => account?.accountType === 'Trial')
    .sort((a1, a2) => a1.name.toLowerCase().localeCompare(a2.name.toLowerCase()));
  const paidAccounts = mapAccounts
    .filter(account => account?.accountType !== 'Trial' && account?.resourceType !== AccountResourceType.ARM)
    .sort((a1, a2) => a1.location <= a2.location && a1.name.toLowerCase().localeCompare(a2.name.toLowerCase()));
  const armAccounts = mapAccounts
    .filter(account => account?.resourceType === AccountResourceType.ARM)
    .sort((a1, a2) => a1.location <= a2.location && a1.name.toLowerCase().localeCompare(a2.name.toLowerCase()));

  return [...trialAccounts, ...paidAccounts, ...armAccounts];
});

export const selectAccountsFilterName = createSelector(getAccountsState, state => state.accountsFilterValue);

export const selectFilteredUIAccounts = () =>
  createSelector(selectUIAccounts, selectCurrentAccount, selectAccountsFilterName, (accounts, selectedAccount, value) => {
    return [
      ...accounts?.filter(
        acc =>
          acc?.id !== selectedAccount?.id &&
          (acc.name?.toLowerCase().includes(value?.toLowerCase()) || acc.location?.toLowerCase().includes(value?.toLowerCase()))
      )
    ];
  });

export const selectedUIAccount = createSelector(selectCurrentAccount, account => ({
  id: account?.id || '',
  location: account?.location || '',
  name: account?.name || '',
  accountType: account?.accountType || 'Trial',
  resourceType: account?.resourceType
}));

export const getSelectedAccountStatistics = createSelector(selectedAccountContract, selectedAccount => {
  return selectedAccount?.statistics;
});

export const getSelectedAccountQuota = createSelector(selectedAccountContract, selectedAccount => {
  return selectedAccount?.quotaUsage;
});

export const getSelectedAccountLocation = createSelector(selectCurrentAccount, selectedAccount => {
  return selectedAccount?.location;
});

export const getSelectedAccountSettings = createSelector(getAccountsState, state => {
  return state?.selectedAccountSettings;
});

export const getIncludeCelebSettings = createSelector(getSelectedAccountSettings, state => {
  return state?.includeCelebrityRecognition;
});

export const selectAccountTokenPermission = createSelector(getAccountsState, state => {
  return state?.selectedAccountAccessTokenPermission;
});

export const selectAccountResourceType = createSelector(selectCurrentAccount, account => {
  if (account?.accountType?.toLowerCase() === AccountResourceType.TRIAL.toLowerCase()) {
    return AccountResourceType.TRIAL;
  } else if (account?.accountType !== AccountResourceType.TRIAL.toLowerCase() && account?.resourceType !== AccountResourceType.ARM) {
    return AccountResourceType.CLASSIC;
  } else if (account?.resourceType === AccountResourceType.ARM) {
    return AccountResourceType.ARM;
  }
});

export const isArmAmslessAccount = createSelector(selectCurrentAccount, account => {
  return !!account?.storageAccountResourceId;
});

export const isConnectedToOpenAI = createSelector(selectCurrentAccount, account => {
  return !!account?.openAIResourceId;
});

export const isUSAAccount = createSelector(selectCurrentAccount, account => {
  if (!account) {
    return false;
  }

  if (account.accountType?.toLowerCase() === AccountResourceType.TRIAL.toLowerCase()) {
    return true;
  }

  return locationsUS.includes(account.location);
});

export const isTrialAccount = createSelector(selectCurrentAccount, account => {
  return account?.accountType?.toLowerCase() === AccountResourceType.TRIAL.toLowerCase();
});

// get the selectors
const { selectIds, selectEntities, selectAll, selectTotal } = fromAccounts.adapter.getSelectors(getAccountsState);

// select the array of user ids
export const selectAccountIds = selectIds as (state: object) => string[];

// select the dictionary of user entities
export const selectUserEntities = selectEntities;

// select the array of users
export const selectAllAccountsSlim = selectAll;

// select the total user count
export const selectUserTotal = selectTotal;

// ARM Accounts

export const armAccountsLoaded = createSelector(getAccountsState, state => state.armAccountsLoaded);

export const armAccountsError = createSelector(getAccountsState, state => state.armAccountsError);

export const selectErrorType = createSelector(getAccountsState, state => state.errorType);

export const isNetworkAccessNotAllowedError = createSelector(selectErrorType, errorType => errorType === APIErrors.NETWORK_ACCESS_NOT_ALLOWED);
