import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, OnDestroy, OnInit, Output, Input } from '@angular/core';

import { Subject } from 'rxjs';
import { takeUntil, withLatestFrom } from 'rxjs/operators';

import { IDialogEvent } from '@common/modules/shared/components/dialog/interfaces';
import { REPAIR_ACCOUNT_LINK } from '@common/modules/shared/links';
import { TranslateHelperService } from '@common/modules/translation/services/translate-helper.service';
import { FocusableComponent } from '@common/modules/accessibility/focusable/focusable.component';
import { EventCategory, TrackService } from '@common/modules/core/services/track';
import { ActionButtonType } from '@common/modules/shared/components/action-button/interfaces';

import { cancelAction, connectAction } from './actions';
import { resources } from './resources';
import { IUpdatePaidAccountError, IConfigureConnectionData, AMSErrors } from './interfaces';
import { SettingsService } from '../../services/settings.service';

@Component({
  selector: 'app-configure-connection',
  templateUrl: './configure-connection.component.html',
  styleUrls: ['./configure-connection.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConfigureConnectionComponent extends FocusableComponent implements OnInit, OnDestroy {
  @Input() public data: IConfigureConnectionData;

  @Output() public componentEventEmitter = new EventEmitter<IDialogEvent>();

  // Public
  public resources = resources;
  public inProcess = false;
  public isError = false;
  public currentError: IUpdatePaidAccountError = null;
  public configureConnectionParams = {
    subscriptionId: '',
    resourceGroupName: '',
    applicationId: '',
    applicationKey: ''
  };
  public connectAction = connectAction;
  public cancelAction = cancelAction;
  public repairAccountLink = REPAIR_ACCOUNT_LINK;
  public ActionButtonType = ActionButtonType;

  // Private
  private destroy$ = new Subject<void>();

  constructor(
    private translate: TranslateHelperService,
    private cdr: ChangeDetectorRef,
    private settingsService: SettingsService,
    private trackService: TrackService
  ) {
    super();
    this.translate.translateResources(this.resources);
  }

  public ngOnInit() {
    this.trackService.track('settings.account.configure_connection_dialog.init');
    this.setActionsTitles();
    this.addDataListeners();
  }

  public ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public setActionsTitles() {
    [this.connectAction, this.cancelAction].forEach(action => {
      action.title = resources[action.key];
    });
    this.cdr.detectChanges();
  }

  public checkAllowConnect() {
    connectAction.isDisabled = !(
      this.configureConnectionParams.applicationId !== '' &&
      this.configureConnectionParams.applicationKey !== '' &&
      this.configureConnectionParams.resourceGroupName !== '' &&
      this.configureConnectionParams.subscriptionId !== ''
    );
  }

  public reconnectToAzure() {
    this.inProcess = true;
    connectAction.isDisabled = true;
    this.isError = false;
    this.currentError = null;

    const amsUpdateConfig: Microsoft.VideoIndexer.Contracts.UpdateAmsConnectionContract = {
      subscriptionId: this.configureConnectionParams.subscriptionId,
      resourceGroup: this.configureConnectionParams.resourceGroupName,
      aadConnection: {
        applicationId: this.configureConnectionParams.applicationId,
        applicationKey: this.configureConnectionParams.applicationKey
      }
    };

    this.trackService.track('settings.account.configure_connection.update_media_services.update', {
      category: EventCategory.SETTINGS
    });
    this.settingsService.updateAccountMediaServices(amsUpdateConfig);
  }

  public isApplicationKeyError() {
    return this.currentError && this.currentError.type === AMSErrors.AMS_AAD_INVALID_APPLICATION_KEY;
  }

  public onApplicationKeyInput() {
    // Reset INVALID_APPLICATION_KEY error
    if (this.currentError && this.currentError.type === AMSErrors.AMS_AAD_INVALID_APPLICATION_KEY) {
      this.isError = false;
    }
    this.checkAllowConnect();
  }

  public close(success?: boolean) {
    const dialogEvent: IDialogEvent = {
      isDialogClose: true,
      dialogEventData: {
        success: success
      }
    };
    this.componentEventEmitter.emit(dialogEvent);

    this.trackService.track('settings.account.close_configure_connection', {
      category: EventCategory.SETTINGS
    });
  }

  private handleError() {
    this.isError = true;
    this.currentError = this.settingsService.configurationError;
    this.currentError.message = this.resources[this.currentError.message];

    if (!this.currentError.message) {
      this.currentError.message = this.resources.ConfigureConnectionErrorGeneralError;
    }
    this.inProcess = false;
    connectAction.isDisabled = true;
    this.cdr.detectChanges();
  }

  private addDataListeners() {
    this.settingsService.selectAccountSaving$
      .pipe(takeUntil(this.destroy$), withLatestFrom(this.settingsService.selectAccountError$))
      .subscribe(([saving, error]) => {
        if (!saving && this.inProcess) {
          if (!error) {
            this.trackService.track('settings.account.configure_connection.update_media_services.success', {
              category: EventCategory.SETTINGS
            });
            this.close(true);
          } else {
            this.trackService.track('settings.account.configure_connection.update_media_services.failed', {
              category: EventCategory.SETTINGS
            });
            this.handleError();
          }
        }
      });
  }
}
