import { Injectable, Injector } from '@angular/core';
import { HttpEventType, HttpEvent } from '@angular/common/http';

import { Store } from '@ngrx/store';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { ROUTER_NAVIGATION } from '@ngrx/router-store';

import { switchMap, withLatestFrom, catchError, delay } from 'rxjs/operators';
import { EMPTY, Subject } from 'rxjs';

import { saveAs } from 'file-saver';

import { ApiService } from '@common/modules/api/services/api.service';
import { LoggerService } from '@common/modules/core/services/logger/logger.service';
import { EventCategory, TrackService } from '@common/modules/core/services/track';
import { TRANSLATION_DELAY } from '@common/modules/translation/variables';
import { IApiSasContract } from '@common/modules/api/interfaces';
import { AccountPermission } from '@common/modules/shared/interfaces';

import { UploadService } from '../../indexing/services/upload.service';
import { AzureBlobStorageService } from '../../indexing/services/azure-blob-storage.service';
import { ofRoute } from '../../core/services/operations';
import { IState } from '../../core/reducers';
import { VIRoutingMap } from '../../app/routing/routes';
import { IFileBlob } from '../../indexing/interfaces';
import { IUploadTrainingDataFile } from '../../customization/components/language/model-file/interfaces';
import { LanguageCustomizationService } from '../services/language-customization.service';
import { CustomizationUtilsService } from '../services/customization-utils.service';
import { LanguageModelState } from '../../customization/interfaces';
import * as LanguageActions from '../actions/language-model.actions';
import * as SupportedLanguagesActions from '../actions/supported-languages.actions';
import * as fromCore from '../../core/selectors';
import * as fromLanguageModels from '../selectors';
import * as fromCustomizationData from '../selectors';

import LanguageModel = Microsoft.VideoIndexer.Contracts.LanguageModel;
import TrainingDataFile = Microsoft.VideoIndexer.Contracts.TrainingDataFile;

@Injectable()
export class LanguageModelEffects {
  public languageRoute$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ROUTER_NAVIGATION),
        ofRoute(`/${VIRoutingMap.customization.path}/${VIRoutingMap.customizationLanguage.path}`),
        withLatestFrom(this.store.select(fromLanguageModels.isLanguageModelsLoaded)),
        switchMap((action, loaded) => {
          this.logger.log('[LanguageModelEffects] route', action);

          if (loaded) {
            return EMPTY;
          }
          return EMPTY;
        })
      ),
    { dispatch: false }
  );

  public loadLanguageModels$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LanguageActions.loadLanguageModels),
      withLatestFrom(this.store.select(fromCore.selectCurrentAccountId)),
      withLatestFrom(this.store.select(fromCore.selectAccountTokenPermission)),
      withLatestFrom(this.store.select(fromCustomizationData.isLanguageModelsLoaded)),
      switchMap(([[[, accountId], permission], isLoaded]) => {
        // Return empty in case the models already loaded in the store.
        if (isLoaded || !accountId || permission === AccountPermission.RESTRICTED_VIEWER) {
          return EMPTY;
        }

        return this.apiService.Account.Customization.LanguagesModels.getModels(accountId).pipe(
          switchMap((result: LanguageModel[]) => {
            const models = this.getModels(result);
            return [LanguageActions.upsertLanguageModels({ models: models })];
          }),
          catchError(() => {
            return [LanguageActions.failLoadLanguageModels()];
          })
        );
      })
    )
  );

  public addLanguageModel$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LanguageActions.addLanguageModel),
      withLatestFrom(this.store.select(fromCore.selectCurrentAccountId)),
      switchMap(([{ model }, accountId]) => {
        return this.apiService.Account.Customization.LanguagesModels.addModel(accountId, model).pipe(
          switchMap((result: LanguageModel) => {
            this.trackService.track('models.language.add_model.success', {
              category: EventCategory.CUSTOMIZATION,
              modelName: model.modelName
            });

            this.languageCustomizationService.handelModelAdding(true, result?.id);
            return [
              LanguageActions.upsertLanguageModel({ model: result }),
              SupportedLanguagesActions.updateSupportedLanguage({
                supportedLanguage: {
                  id: result.language,
                  changes: { isCreating: false }
                }
              })
            ];
          }),
          catchError(error => {
            this.logger.error('[LanguageModelEffects]', error);
            const emptyModel: LanguageModel = Object.create(null);
            emptyModel.name = model.modelName;
            this.languageCustomizationService.handleLanguageError(error, emptyModel);
            this.trackService.track('models.language.add_model.failed', {
              category: EventCategory.CUSTOMIZATION,
              modelName: model.modelName
            });

            this.languageCustomizationService.handelModelAdding(false, model.language);

            return [
              SupportedLanguagesActions.updateSupportedLanguage({
                supportedLanguage: {
                  id: model.language,
                  changes: { isCreating: false }
                }
              })
            ];
          })
        );
      })
    )
  );

  public updateLanguageModel$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LanguageActions.updateLanguageModel),
      withLatestFrom(this.store.select(fromCore.selectCurrentAccountId)),
      switchMap(([{ model, modelId, oldModel }, accountId]) => {
        return this.apiService.Account.Customization.LanguagesModels.updateModel(accountId, model, modelId).pipe(
          switchMap((result: LanguageModel) => {
            this.customizationUtilsService.displayToast({ LanguageModelEditSuccess: '' }, { name: result.name });
            this.trackService.track('models.language.update_model.success', {
              category: EventCategory.CUSTOMIZATION,
              modelName: model.modelName
            });
            return [LanguageActions.upsertLanguageModel({ model: result })];
          }),
          catchError(error => {
            this.logger.error('[LanguageModelEffects]', error);
            this.customizationUtilsService.displayErrorToast(error, { LanguageModelEditFailed: '' }, {});
            this.trackService.track('models.language.update_model.failed', {
              category: EventCategory.CUSTOMIZATION,
              modelName: model.modelName
            });
            return [LanguageActions.upsertLanguageModel({ model: oldModel })];
          })
        );
      })
    )
  );

  public deleteLanguageModel$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LanguageActions.deleteLanguageModel),
      withLatestFrom(this.store.select(fromCore.selectCurrentAccountId)),
      switchMap(([{ model }, accountId]) => {
        return this.apiService.Account.Customization.LanguagesModels.deleteModel(accountId, model.id).pipe(
          switchMap(() => {
            this.logger.log(`[LanguageModelEffects] Delete Model ${model.id} success`);
            this.customizationUtilsService.displayToast({ LanguageDeleteModelSuccess: '' }, { name: model.name });
            this.trackService.track('models.language.delete_model.success', {
              category: EventCategory.CUSTOMIZATION,
              modelName: model.name
            });
            return EMPTY;
          }),
          catchError(error => {
            this.logger.error('[LanguageModelEffects]', error);
            this.customizationUtilsService.displayErrorToast(error, { LanguageModelFailedDeleteModel: '' }, {});
            this.trackService.track('models.language.delete_model.failed', {
              category: EventCategory.CUSTOMIZATION,
              modelName: model.name
            });
            return [LanguageActions.upsertLanguageModel({ model: model })];
          })
        );
      })
    )
  );

  public deleteLanguageModelFile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LanguageActions.deleteFile),
      withLatestFrom(this.store.select(fromCore.selectCurrentAccountId)),
      switchMap(([{ modelId, fileId }, accountId]) => {
        return this.apiService.Account.Customization.LanguagesModels.deleteModelFile(accountId, modelId, fileId).pipe(
          switchMap(() => {
            this.logger.log(`[LanguageModelEffects] Delete Model ${modelId} File ${fileId} success`);
            this.customizationUtilsService.displayToast({ LanguageDeleteFileSuccess: '' }, { name: '' });

            this.trackService.track('models.language.delete_file.success', {
              category: EventCategory.CUSTOMIZATION,
              fileId: fileId
            });
            return [LanguageActions.deleteLanguageModelFile({ modelId, fileId })];
          }),
          catchError(error => {
            this.logger.error('[LanguageModelEffects]', error);
            this.trackService.track('models.language.delete_file.failed', {
              category: EventCategory.CUSTOMIZATION,
              fileId: fileId
            });
            this.customizationUtilsService.displayErrorToast(error, { LanguageModelFailedDeleteFile: '' }, {});
            return EMPTY;
          })
        );
      })
    )
  );

  public downloadLanguageModelFile$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(LanguageActions.downloadFile),
        withLatestFrom(this.store.select(fromCore.selectCurrentAccountId)),
        switchMap(([{ modelId, fileId, fileName }, accountId]) => {
          return this.apiService.Account.Customization.LanguagesModels.downloadModelFile(accountId, modelId, fileId).pipe(
            switchMap((result: Microsoft.VideoIndexer.Contracts.TrainingDataFileContent) => {
              this.logger.log(`[LanguageModelEffects] Download Model ${modelId} File ${fileId} success!`);
              this.downloadFile(result.content, fileName, 'txt', { type: 'text/html;charset=utf-8' });
              this.trackService.track('models.language.download_file.success', {
                category: EventCategory.CUSTOMIZATION,
                fileName: fileName
              });
              return EMPTY;
            }),
            catchError(error => {
              this.logger.error('[LanguageModelEffects]', error);
              const file: IFileBlob = new File(null, fileName);
              this.languageCustomizationService.handleLanguageFileError(error, file, true);
              this.trackService.track('models.language.download_file.failed', {
                category: EventCategory.CUSTOMIZATION,
                fileName: fileName
              });
              return EMPTY;
            })
          );
        })
      ),
    { dispatch: false }
  );

  public downloadFromEditFile$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(LanguageActions.downloadFromEditFile),
        switchMap(({ modelId, fileId, fileName }) => {
          return this.store.select(fromCustomizationData.getLanguageModelFile(modelId, fileId)).pipe(
            switchMap((result: Microsoft.VideoIndexer.Contracts.TrainingDataFileContent) => {
              if (result.hasCrisEdits) {
                if (result.crisEdits.length) {
                  const crisResult = this.languageCustomizationService.convertTranscriptEditToString(result.crisEdits, false);
                  this.logger.log(`[LanguageModelEffects] Get Cris from edits content with Model ${modelId} File ${fileId} success!`);
                  this.downloadFile(crisResult, fileName, 'txt', { type: 'text;charset=utf-8' });
                  this.trackService.track('models.language.download_cfe_file.success', {
                    category: EventCategory.CUSTOMIZATION,
                    fileName: fileName
                  });
                } else {
                  return [LanguageActions.loadLanguageModelCrisEditsContent({ modelId })];
                }
              }
              return EMPTY;
            }),
            catchError(error => {
              this.logger.error('[LanguageModelEffects]', error);
              this.languageCustomizationService.handleLanguageFileError(error, null, true);
              this.trackService.track('models.language.download_cfe_file.failed', {
                category: EventCategory.CUSTOMIZATION,
                fileName: fileName
              });
              return EMPTY;
            })
          );
        })
      ),
    { dispatch: false }
  );

  public uploadLanguageModelFile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LanguageActions.uploadLanguageModelFile),
      withLatestFrom(this.store.select(fromCore.selectCurrentAccountId)),
      switchMap(([{ file, modelId }, accountId]) => {
        const emptyFile = this.getEmptyFileFromTrainingFile(file);
        this.store.dispatch(LanguageActions.updateUploadProgress({ file: emptyFile, modelId }));
        return this.uploadService.getSas(accountId, file.name, file.size).pipe(
          switchMap((res: IApiSasContract) => {
            const mergedFile: IFileBlob = this.azureBlobService.generateFile(file);
            mergedFile.baseUrl = res.baseUrl;
            mergedFile.sasToken = res.sasToken;
            mergedFile.blobPath = res.baseUrl + res.sasToken;
            return this.azureBlobService.uploadToBlobStorage(mergedFile, res).pipe(
              switchMap((event: HttpEvent<object>) => {
                switch (event.type) {
                  case HttpEventType.UploadProgress:
                    const newFile = { ...emptyFile, progress: (event.loaded / event.total) * 100 };
                    return [LanguageActions.updateUploadProgress({ file: newFile, modelId })];
                  case HttpEventType.Response:
                    this.trackService.track('models.language.upload.success', {
                      category: EventCategory.CUSTOMIZATION,
                      modelId: modelId,
                      fileName: mergedFile.name,
                      source: mergedFile.blobPath
                    });
                    return [LanguageActions.uploadFileToServer({ file: mergedFile, modelId: modelId, fileId: emptyFile.id })];
                  default:
                    return EMPTY;
                }
              }),
              catchError(error => {
                this.logger.error('[LanguageModelEffects]', error);
                this.languageCustomizationService.handleLanguageFileError(error, file);
                this.trackService.track('models.language.upload.failed', {
                  category: EventCategory.CUSTOMIZATION,
                  modelId: modelId,
                  fileName: mergedFile.name,
                  source: mergedFile.blobPath
                });

                return [LanguageActions.cancelLanguageModelFile({ file: file, modelId: modelId })];
              })
            );
          })
        );
      })
    )
  );

  public uploadFileToServer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LanguageActions.uploadFileToServer),
      withLatestFrom(this.store.select(fromCore.selectCurrentAccountId)),
      switchMap(([{ file, modelId, fileId }, accountId]) => {
        const data = new FormData();
        data.append(file.name, file.blobPath);
        return this.apiService.Account.Customization.LanguagesModels.uploadModelFile(accountId, modelId, data).pipe(
          switchMap((res: LanguageModel) => {
            const model = this.getModel(res);
            return [LanguageActions.upsertLanguageModel({ model: model }), LanguageActions.removeUpdatingFile({ modelId: model.id, id: fileId })];
          }),
          catchError(error => {
            this.logger.error('[LanguageModelEffects] ', error);
            this.languageCustomizationService.handleLanguageFileError(error, file);
            return [
              LanguageActions.cancelLanguageModelFile({ file: file, modelId: modelId }),
              LanguageActions.removeUpdatingFile({ modelId: modelId, id: fileId })
            ];
          })
        );
      })
    )
  );

  public loadLanguageModelFileContent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LanguageActions.loadLanguageModelFileContent),
      withLatestFrom(this.store.select(fromCore.selectCurrentAccountId)),
      switchMap(([{ fileId, modelId }, accountId]) => {
        return this.apiService.Account.Customization.LanguagesModels.getModelFile(accountId, modelId, fileId).pipe(
          switchMap((res: TrainingDataFile) => {
            this.trackService.track('models.language.load_file.success', {
              category: EventCategory.CUSTOMIZATION,
              fileId: fileId
            });
            return [LanguageActions.updateLanguageModelFileContent({ file: res, modelId: modelId })];
          }),
          catchError(error => {
            this.logger.error('[LanguageModelEffects]', error);
            const file: IFileBlob = new File(null, fileId);
            this.trackService.track('models.language.load_file.failed', {
              category: EventCategory.CUSTOMIZATION,
              fileId: fileId
            });
            this.languageCustomizationService.handleLanguageFileError(error, file);
            return EMPTY;
          })
        );
      })
    )
  );

  public loadLanguageModelCrisEditsContent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LanguageActions.loadLanguageModelCrisEditsContent),
      withLatestFrom(this.store.select(fromCore.selectCurrentAccountId)),
      switchMap(([{ modelId }, accountId]) => {
        return this.apiService.Account.Customization.LanguagesModels.getModelCrisEdits(accountId, modelId).pipe(
          switchMap((res: Microsoft.VideoIndexer.Contracts.TrainingDataFromEdits[]) => {
            this.trackService.track('models.language.load_cfe_file.success', {
              category: EventCategory.CUSTOMIZATION,
              modelId: modelId
            });
            return [LanguageActions.updateLanguageModelCrisEditsContent({ crisEdits: res, modelId: modelId })];
          }),
          catchError(error => {
            this.logger.error('[LanguageModelEffects] ', error);
            this.trackService.track('models.language.load_cfe_file.failed', {
              category: EventCategory.CUSTOMIZATION,
              modelId: modelId
            });
            this.languageCustomizationService.handleLanguageFileError(error, null);
            return EMPTY;
          })
        );
      })
    )
  );

  public processLanguageModels$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LanguageActions.upsertLanguageModels),
      switchMap(({ models }) => {
        const processingModels = models.filter(model => model.state === LanguageModelState.Processing || model.state === LanguageModelState.Waiting);
        if (processingModels.length) {
          const actionsList = [];
          processingModels.forEach(model => {
            actionsList.push(LanguageActions.pullTrainingModel({ model: model }));
          });

          return [...actionsList];
        }

        return EMPTY;
      })
    )
  );

  public trainModel$ = createEffect(() =>
    this.actions$.pipe(
      // Filters by Action Creator 'login'
      ofType(LanguageActions.trainModel),
      withLatestFrom(this.store.select(fromCore.selectCurrentAccountId)),
      switchMap(([{ model }, accountId]) => {
        return this.apiService.Account.Customization.LanguagesModels.trainModel(accountId, model.id).pipe(
          switchMap((result: LanguageModel) => {
            const actions = [];
            actions.push(LanguageActions.upsertLanguageModel({ model: this.getModel(result) }));
            if (result && result.state !== 'Complete') {
              actions.push(LanguageActions.pullTrainingModel({ model: result }));
            }

            this.trackService.track('models.language.train_model.success', {
              category: EventCategory.CUSTOMIZATION,
              modelName: model.name
            });
            return actions;
          }),
          catchError(error => {
            this.logger.error('[LanguageModelEffects] ', error);
            this.languageCustomizationService.handleLanguageError(error, model);
            this.trackService.track('models.language.train_model.failed', {
              category: EventCategory.CUSTOMIZATION,
              modelName: model.name
            });
            return [LanguageActions.upsertLanguageModel({ model: { ...model, state: 'None' } })];
          })
        );
      })
    )
  );

  public pullTrainingModel$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LanguageActions.pullTrainingModel),
      withLatestFrom(this.store.select(fromCore.selectCurrentAccountId)),
      delay(30000),
      switchMap(([{ model }, accountId]) => {
        return this.apiService.Account.Customization.LanguagesModels.getModel(accountId, model.id).pipe(
          switchMap((result: LanguageModel) => {
            const actions = [];
            actions.push(LanguageActions.upsertLanguageModel({ model: this.getModel(result) }));
            if (result && result.state !== 'Complete') {
              actions.push(LanguageActions.pullTrainingModel({ model: result }));
            }

            return actions;
          }),
          catchError(error => {
            this.logger.error('[LanguageModelEffects] ', error);
            this.languageCustomizationService.handleLanguageError(error, model);
            return [LanguageActions.upsertLanguageModel({ model: { ...model, state: 'None' } })];
          })
        );
      })
    )
  );

  public filesIndex = 0;
  private customizationUtilsService: CustomizationUtilsService;
  private languageCustomizationService: LanguageCustomizationService;

  constructor(
    private actions$: Actions,
    private apiService: ApiService,
    private store: Store<IState>,
    private logger: LoggerService,
    private uploadService: UploadService,
    private azureBlobService: AzureBlobStorageService,
    private trackService: TrackService,
    private injector: Injector
  ) {
    setTimeout(() => {
      this.init();
    }, TRANSLATION_DELAY);
  }

  public downloadFile(fileContent: string, fileName: string, fileType: string, fileProperties: BlobPropertyBag): Subject<Blob> {
    const downloadSubject = new Subject<Blob>();
    if (!fileName) {
      downloadSubject.error('FileName not exists');
    } else {
      try {
        const file = new Blob() ? new Blob([fileContent], fileProperties) : new File([fileContent], fileName, fileProperties);
        saveAs(file, `${fileName.replace(/\s/g, '_')}`);
        downloadSubject.next(file);
      } catch (e) {
        downloadSubject.error(e);
        throw e;
      }
    }
    return downloadSubject;
  }

  private init() {
    this.customizationUtilsService = this.injector.get(CustomizationUtilsService);
    this.languageCustomizationService = this.injector.get(LanguageCustomizationService);
  }

  private getEmptyFileFromTrainingFile(file: IFileBlob): IUploadTrainingDataFile {
    const trainingFile: Microsoft.VideoIndexer.Contracts.TrainingDataFile = Object.create(null);
    trainingFile.name = file.name;
    return { file: trainingFile, inProgress: true, progress: 0, id: this.filesIndex++ };
  }

  private getModels(models: LanguageModel[]) {
    models.forEach(model => {
      model = this.getModel(model);
    });

    return models;
  }

  private getModel(model: LanguageModel) {
    if (model.hasCrisEdits && !model.files.filter(f => f.id === `cris-edits-id-${model.id}`).length) {
      const file: TrainingDataFile = {
        id: `cris-edits-id-${model.id}`,
        name: 'From transcript edits',
        fileName: 'From transcript edits',
        hasCrisEdits: true,
        modelId: model.id,
        crisEdits: [],
        enable: false,
        creator: '',
        creationTime: '',
        modelType: null,
        description: '',
        lastUpdateTime: '',
        active: false,
        fileContent: '',
        groupId: null
      };
      model.files.unshift(file);
    }
    return model;
  }
}
