import { OnInit, ChangeDetectorRef, OnDestroy, EventEmitter, Output, ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { Subject, take, takeUntil, withLatestFrom } from 'rxjs';

import { startCase } from 'lodash-es';

import { PrivacyMode } from '@common/modules/core/interfaces';
import { TranslateHelperService } from '@common/modules/translation/services/translate-helper.service';
import { StreamingPreset } from '@common/modules/shared/interfaces';
import { FeatureSwitchService } from '@common/modules/core/services/feature-switch/feature-switch.service';
import { FeatureSwitch } from '@common/modules/core/services/interfaces';

import { EdgeExtensionsStoreService } from '../../../../core/services/edge-extensions-store.service';
import { presetsWithBasicVideo } from '../settings/indexing-presets/actions';
import { defaultBrandCategory } from '../../../interfaces';
import { resources } from './resources';
import { IndexingStoreService } from '../../../services/indexing-store.service';
import { PresetHelperService } from '../../../services/preset-helper.service';
import { ISummaryRow, RowValueColor } from './interfaces';

@Component({
  selector: 'vi-indexing-summary',
  templateUrl: './indexing-summary.component.html',
  styleUrls: ['./indexing-summary.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IndexingSummaryComponent implements OnInit, OnDestroy {
  @Input() public rowValueColor?: string = RowValueColor.Secondary;

  @Output() public createAccountClicked = new EventEmitter<void>();
  public resources = resources;

  public selectedPeopleModel = '';
  public selectedLogoGroup = '';
  public selectedBrandCategories = '';
  public selectedLanguage = '';
  public selectedPrivacy = '';
  public selectedStreaming = '';
  public selectedPreset = '';
  public includedAIs = '';
  public excludedAIs = '';
  public indexingSettingInfo: ISummaryRow[] = [];

  private presets;
  private destroy$ = new Subject<void>();
  private hasSelectedEdgeExtension: boolean = false;
  private isExcludeAIsEnabled = false;

  private readonly DISPLAY_NAME_KEY = 'DisplayName';

  constructor(
    private translate: TranslateHelperService,
    private indexingStore: IndexingStoreService,
    private cdr: ChangeDetectorRef,
    private edgeExtensionService: EdgeExtensionsStoreService,
    private presetHelperService: PresetHelperService,
    private featureSwitchService: FeatureSwitchService
  ) {}

  public ngOnInit(): void {
    this.isExcludeAIsEnabled = this.featureSwitchService.featureSwitch(FeatureSwitch.ExcludeAIs);
    this.translate.translateResourcesInstant(this.resources);
    this.handleEdge();
    this.handleIndexingSetting();
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public createAccount() {
    this.createAccountClicked.emit();
  }

  private handleEdge() {
    this.edgeExtensionService.hasSelectedEdgeExtension$.pipe(takeUntil(this.destroy$)).subscribe(hasSelectedEdgeExtension => {
      this.hasSelectedEdgeExtension = hasSelectedEdgeExtension;
      this.presets = this.hasSelectedEdgeExtension ? this.presetHelperService.getEdgePresets() : presetsWithBasicVideo;
    });
  }

  private handleIndexingSetting() {
    this.indexingStore.indexingSettingSummary$
      .pipe(take(1), withLatestFrom(this.indexingStore.indexingLanguages$), withLatestFrom(this.indexingStore.isLoadSupportedAIsError$))
      .subscribe(([[summary, languages], isLoadSupportedAIsError]) => {
        this.selectedPeopleModel = summary.peopleModel;
        this.selectedBrandCategories = this.getSelectedBrands(summary.brandsCategories);
        this.selectedPrivacy = this.getSelectedPrivacy(summary.privacy);
        this.selectedStreaming = this.getSelectedStreaming(summary.streamingPreset);
        this.selectedPreset = this.getSelectedPreset(summary.indexingPreset, summary.excludeRAI, isLoadSupportedAIsError);
        this.selectedLogoGroup = summary.logoGroup;
        const language = languages.find(lang => lang.id === summary.languageId);
        this.selectedLanguage = language.name;

        if (this.isExcludeAIsEnabled && !isLoadSupportedAIsError) {
          this.includedAIs = this.stringifySupportedAIs(summary.includedAIs);
          this.excludedAIs = this.stringifySupportedAIs(summary.excludedAIs);
        }

        this.handleRows();
        this.cdr.detectChanges();
      });
  }

  private handleRows(): void {
    this.indexingSettingInfo = [
      { label: `${this.resources.UploadVideoSourceLanguage}:`, value: this.selectedLanguage, show: true },
      {
        label: `${this.resources.IndexingPresetLabel}:`,
        value: this.selectedPreset,
        show: true,
        subRows: [
          {
            label: `${this.resources.IncludedModelsLabel}: `,
            value: this.includedAIs,
            show: this.isExcludeAIsEnabled && !!this.includedAIs
          },
          {
            label: `${this.resources.ExcludedModelsLabel}: `,
            value: this.excludedAIs,
            show: this.isExcludeAIsEnabled && !!this.excludedAIs
          }
        ]
      },
      { label: `${this.resources.UploadPrivacyLabel}:`, value: this.selectedPrivacy, show: !this.hasSelectedEdgeExtension },
      { label: `${this.resources.UploadStreaming}:`, value: this.selectedStreaming, show: !this.hasSelectedEdgeExtension },
      { label: `${this.resources.UploadPeopleModelOption}:`, value: this.selectedPeopleModel, show: !!this.selectedPeopleModel },
      { label: `${this.resources.UploadLogoGroupOption}:`, value: this.selectedLogoGroup, show: !!this.selectedLogoGroup },
      { label: `${this.resources.BrandsModel}:`, value: this.selectedBrandCategories, show: !!this.selectedBrandCategories }
    ];
  }

  private getSelectedBrands(brands: string[]): string {
    if (brands?.includes(defaultBrandCategory)) {
      return [this.resources.UploadServiceDefaultName].concat(brands.filter(brand => brand !== defaultBrandCategory)).join(', ');
    } else {
      return brands?.join(', ');
    }
  }

  private getSelectedPrivacy(privacy: string): string {
    switch (privacy) {
      case PrivacyMode.PUBLIC:
        return this.resources.AppSettingsPublic;
      case PrivacyMode.PRIVATE:
        return this.resources.AppSettingsPrivate;
    }
  }

  private getSelectedStreaming(streaming: string): string {
    switch (streaming) {
      case StreamingPreset.SingleBitrate:
        return this.resources.UploadServiceSingleBiterate;
      case StreamingPreset.NoStreaming:
        return this.resources.UploadServiceNoStreaming;
      case StreamingPreset.AdaptiveBitrate:
        return this.resources.UploadServiceAdaptiveBiterate;
    }
  }

  private getSelectedPreset(preset, excludeRAI, isLoadSupportedAIsError) {
    const selected = this.presets.find(p => p.value === preset);
    if (excludeRAI && (!this.isExcludeAIsEnabled || isLoadSupportedAIsError)) {
      return `${this.resources[selected.key]} (${this.resources.ExcludeRAI})`;
    }
    return this.resources[selected.key];
  }

  private stringifySupportedAIs(supportedAIs) {
    return supportedAIs.map(ai => this.translateAI(ai)).join(', ');
  }

  private translateAI(ai: string) {
    let modelDisplayName = this.translate.instant(`${ai + this.DISPLAY_NAME_KEY}`);

    // if the AI name is not translated, use the startCase function as a fallback.
    if (modelDisplayName === `${ai + this.DISPLAY_NAME_KEY}`) {
      modelDisplayName = startCase(ai);
    }

    return modelDisplayName;
  }
}
