import { Injectable, Injector } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ROUTER_NAVIGATION } from '@ngrx/router-store';
import { Store } from '@ngrx/store';

import {
  catchError,
  combineLatestWith,
  concatMap,
  delay,
  filter,
  map,
  mergeWith,
  skipWhile,
  switchMap,
  take,
  takeUntil,
  tap,
  withLatestFrom
} from 'rxjs/operators';
import { EMPTY, of } from 'rxjs';

import { camelCase } from 'lodash-es';

import { TimeInterval, hasDatePassed } from '@common/modules/utils/time';
import { envTypes } from '@common/modules/api/interfaces';
import { ApiService } from '@common/modules/api/services/api.service';
import { AccountResourceType } from '@common/modules/auth/interfaces';
import { AuthService } from '@common/modules/auth/services/auth.service';
import { ConfigService } from '@common/modules/core/services/config/config.service';
import { FeatureSwitchService } from '@common/modules/core/services/feature-switch/feature-switch.service';
import { FeatureSwitch, NavigationState } from '@common/modules/core/services/interfaces';
import { LoggerService } from '@common/modules/core/services/logger/logger.service';
import { EventCategory, TrackService } from '@common/modules/core/services/track';
import { CommonTrackingDataService } from '@common/modules/core/services/track/common-tracking-data.service';
import { IStripData, MessageType } from '@common/modules/shared/components/strip/interfaces';
import { StripService } from '@common/modules/shared/components/strip/strip.service';
import { AccountPermission, StorageCacheKey } from '@common/modules/shared/interfaces';
import { LocalStorageService } from '@common/modules/shared/services/local-storage.service';
import { TranslateHelperService } from '@common/modules/translation/services/translate-helper.service';
import { TRANSLATION_DELAY } from '@common/modules/translation/variables';
import { APIErrors } from '@common/modules/core/services/toast/errors';
import { NotificationsService } from '@common/modules/notifications/services/notifications.service';
import {
  INotification,
  NotificationIcon,
  NotificationLevel,
  NotificationMessageType,
  NotificationType
} from '@common/modules/core/services/toast/interfaces';
import { AppNavigationService } from '@common/modules/core/services/app/app-navigation.service';
import { getLastArmStorageKey } from '@common/modules/shared/arm';
import { ToastService } from '@common/modules/core/services/toast/toast.service';
import { CacheStorageService, ICacheResponse } from '@common/modules/core/services/cache-storage/cache-storage.service';
import { DialogService } from '@common/modules/shared/components/dialog/dialog.service';
import { ActionButtonType } from '@common/modules/shared/components/action-button/interfaces';
import { IDialogEvent } from '@common/modules/shared/components/dialog/interfaces';
import { UIActionType } from '@common/modules/insights/interfaces';
import { UtilsService } from '@common/modules/shared/services/utils.service';

import { AccountUnavailableDialogComponent } from '../../shell/components/account-unavailable-dialog/account-unavailable-dialog.component';
import { EdgeExtensionsStoreService } from '../services/edge-extensions-store.service';
import { VIRoutingMap } from '../../app/routing/routes';
import { CustomizationUtilsService } from '../../customization-data/services/customization-utils.service';
import { SettingsService } from '../../settings/services/settings.service';
import { IArmAccountCacheData } from '../interfaces';
import { IState } from '../reducers';
import { FaceGatePopupService } from '../services/face-gate-popup.service';
import { getARMLocation, isAccountDailyCountQuotaExceeded, isAccountDailyDurationQuotaExceeded, isAccountDurationQuotaExceeded } from './utils';
import { TRIAL_AMSLESS_DATE } from '../amslessDates';
import { CoreStoreService } from '../services/core-store.service';
import * as fromRouter from '../../core/reducers/router';
import * as fromCore from '../../core/selectors';
import * as fromIndexing from '../../indexing/core/selectors';
import * as LanguageModelActions from '../../customization-data/actions/language-model.actions';
import * as UploadActions from '../../indexing/core/actions';
import * as LanguageIdActions from '../../customization-data/actions/language-id.actions';
import * as AccountsActions from '../actions/accounts.actions';
import * as RouterActions from '../actions/router.actions';
import * as UserActions from '../actions/user.actions';

import AccountContractSlim = Microsoft.VideoIndexer.Contracts.AccountContractSlim;

@Injectable()
export class AccountsEffects {
  public readonly JOINING_DELAY = 2000; // 2 seconds

  // loadAccounts - load all user accounts
  public loadAccounts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AccountsActions.getAccounts),
      switchMap(() => {
        this.logger.log(`[AccountsEffects] loadAccounts`);

        return this.apiService.Account.getAccounts(null, true).pipe(
          switchMap(accounts => {
            this.logger.log(`[AccountsEffects] loadAccounts success`);
            return [AccountsActions.upsertAccounts({ accounts })];
          }),
          catchError(error => {
            return EMPTY;
          })
        );
      })
    )
  );

  // joinAccount - send request to join account
  public joinAccount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AccountsActions.joinAccount),
      switchMap(({ accountId, inviteCode, location }) => {
        this.trackService.track('accounts.join.init', {
          category: EventCategory.ACCOUNTS,
          accountId,
          inviteCode,
          location
        });
        this.logger.log(`[AccountsEffects] join ${accountId} ${inviteCode}`);
        return this.apiService.Account.join(accountId, inviteCode, null, location).pipe(
          switchMap(() => {
            this.trackService.track('accounts.join.success', {
              category: EventCategory.ACCOUNTS,
              accountId,
              inviteCode,
              location
            });
            this.logger.log(`[AccountsEffects] joined ${accountId} ${inviteCode}`);
            this.settingsService.toastJoinAccountSuccess(accountId);
            return [
              RouterActions.Go({
                path: [`/${VIRoutingMap.mediaGallery.path}/${VIRoutingMap.galleryLibrary.path}`],
                extras: null,
                queryParams: null,
                removeParams: true
              }),
              UserActions.loadUserDetails({ forceLoad: true, selectAccountId: accountId })
            ];
          }),
          catchError(error => {
            this.trackService.track('accounts.join.fail', {
              category: EventCategory.ACCOUNTS,
              accountId,
              inviteCode,
              location
            });
            this.settingsService.toastJoinAccountError(accountId);
            return [
              RouterActions.Go({
                path: [`/${VIRoutingMap.mediaGallery.path}/${VIRoutingMap.galleryLibrary.path}`],
                extras: null,
                queryParams: null,
                removeParams: true
              })
            ];
          })
        );
      })
    )
  );

  // joinRoute - listen to router navigation to check if need to join account
  // called on router navigation with join query params
  public joinRoute$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ROUTER_NAVIGATION),
      delay(this.JOINING_DELAY),
      withLatestFrom(this.store.select(fromRouter.getQueryParams)),
      switchMap(([, queryParams]) => {
        if (!this.authService.isAuthenticated()) {
          return EMPTY;
        }

        const returnUrlParamValue = queryParams?.returnUrl;
        if (returnUrlParamValue) {
          // Check if video link and navigate to player page
          if (returnUrlParamValue.includes(window.location.host)) {
            window.location.replace(returnUrlParamValue);
          }

          // Check if Invite link
          // Remove /? chars from returnUrl value
          const params = JSON.parse(
            '{"' +
              decodeURI(returnUrlParamValue)
                .replace(/\\/g, '\\\\')
                .replace(/\?|\//g, '')
                .replace(/"/g, '\\"')
                .replace(/&/g, '","')
                .replace(/=/g, '":"') +
              '"}'
          );

          this.logger.log(`[AccountsEffects] returnUrlParam: ${params}`);
          return [
            AccountsActions.joinAccount({
              accountId: params.accountId,
              inviteCode: params.invitationId,
              location: params?.location
            })
          ];
        }

        if (queryParams?.accountId && queryParams?.invitationId) {
          this.logger.log(`[AccountsEffects] join - accountId: ${queryParams.accountId}, invitationId: ${queryParams.invitationId}`);

          return [
            AccountsActions.joinAccount({
              accountId: queryParams.accountId,
              inviteCode: queryParams.invitationId,
              location: queryParams?.location
            })
          ];
        }
        return EMPTY;
      })
    )
  );

  // getSelectedAccountDetails effect - gets the expanded details for the selected account.
  // called on select account change
  public getSelectedAccountDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AccountsActions.selectAccount),
      withLatestFrom(this.store.select(fromCore.selectedAccountContract)),
      withLatestFrom(this.store.select(fromCore.selectCurrentAccount)),
      withLatestFrom(this.store.select(fromCore.selectedAccountLoaded)),
      switchMap(([[[{ id, forceLoad, doNotClearSelectedExtension }, account], selectCurrentAccount], selectedAccountLoaded]) => {
        if (selectCurrentAccount) {
          const location = getARMLocation(selectCurrentAccount, this.configService);
          this.apiService.setApiBaseLocation(location, selectCurrentAccount.accountType);
          this.authService.UserAccount = selectCurrentAccount;
        }

        id = id || selectCurrentAccount?.id;
        if (account?.id === id && !forceLoad && selectedAccountLoaded) {
          this.logger.log(`[AccountsEffects] get selected account in state`);
          return EMPTY;
        }

        if (this.mediaConnectionStripId) {
          this.stripService.hideStrip(this.mediaConnectionStripId);
        }

        if (this.openAIStripId) {
          this.stripService.hideStrip(this.openAIStripId);
        }

        if (!doNotClearSelectedExtension) {
          this.edgeExtensionsStore.clearSelectedEdgeExtension();
          this.apiService.edgeExtensionApiUrl = null;
        }

        if (selectCurrentAccount?.resourceType === AccountResourceType.ARM) {
          return [AccountsActions.selectArmAccount({ id })];
        }

        this.store.dispatch(AccountsActions.selectAccountProcessing());

        this.logger.log(`[AccountsEffects] get selected account ${id}`);
        this.trackService.track('account_effect.select_account', {
          category: EventCategory.ACCOUNTS
        });
        return this.apiService.Account.getAccount(id, true).pipe(
          takeUntil(this.actions$.pipe(ofType(AccountsActions.selectArmAccount))),
          withLatestFrom(this.store.select(fromCore.isArmAmslessAccount)),
          switchMap(([res, isArmAmslessAccount]) => {
            this.logger.log(`[AccountsEffects] get selected account success`);
            this.localStorageService.setItem(StorageCacheKey.LastAccountId, id);
            this.localStorageService.setItem(StorageCacheKey.LastAccountType, AccountResourceType.TRIAL);

            this.commonTrackingDataService.accountType = res?.accountType;
            this.commonTrackingDataService.isAmslessAccount = isArmAmslessAccount;
            if (res?.isPaid && res?.mediaServices && !res?.mediaServices.connected) {
              const resource = { StripAmsAddError: '' };
              this.translateService.translateResources(resource);
              const stripData: IStripData = {
                text: resource.StripAmsAddError,
                iconClass: 'i-vi-error',
                show: true,
                messageType: MessageType.ERROR,
                linkCallback: this.stripCallback.bind(this),
                trackingName: 'media.connection.error'
              };
              this.mediaConnectionStripId = this.stripService.trigger(stripData);
            }

            this.handleTrialAccountAmslessNotification(res);
            this.handleAccountQuotaNotification(res);
            this.trackService.track('account_effect.select_account.success', {
              category: EventCategory.ACCOUNTS
            });
            const actions = [];
            // load languages models - to check if account has training models
            actions.push(AccountsActions.addSelectedAccount({ account: res }));
            actions.push(LanguageModelActions.loadLanguageModels());
            actions.push(AccountsActions.loadAccountSettings({ id: res.id }));

            const isAutoDetectFeatureEnable = this.featureSwitchService.featureSwitch(FeatureSwitch.AutoDetectLanguage);
            if (isAutoDetectFeatureEnable) {
              actions.push(LanguageIdActions.loadDefaultLanguages());
              actions.push(LanguageIdActions.loadSelectedLanguages());
            }
            return actions;
          }),
          catchError(error => {
            this.commonTrackingDataService.accountType = '';
            this.trackService.track('account_effect.select_account.failed', {
              category: EventCategory.ACCOUNTS,
              data: {
                error
              }
            });
            return [
              AccountsActions.addSelectedAccountFailed({
                errorType: error?.error?.ErrorType
              })
            ];
          })
        );
      })
    )
  );

  // updateAccount effect - update account details
  public updateAccount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AccountsActions.updateAccountContract),
      switchMap(({ account, newAccount }) => {
        this.logger.log('[AccountsEffects] Update account in store');
        return this.apiService.Account.updateAccount(newAccount.id, newAccount).pipe(
          switchMap(() => {
            this.logger.log('[AccountsEffects] Update account success');
            this.settingsService.handleUpdateAccount(true);
            return [AccountsActions.updateSelectedAccount({ account: newAccount })];
          }),
          catchError(err => {
            this.logger.log('[AccountsEffects] Update account failed');
            this.settingsService.handleUpdateAccount(false, err);
            return [
              AccountsActions.updateAccount({ account: { id: account.id, changes: account } }),
              AccountsActions.updateAccountState({ error: true }),
              AccountsActions.updateSelectedAccount({ account: account })
            ];
          })
        );
      })
    )
  );

  public getTrialAccountUsage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UploadActions.uploadFileToServerFailed),
      withLatestFrom(this.store.select(fromCore.selectedAccountContract)),
      concatMap(([{ error, storeFile }, account]) => {
        // quota error relevant only to trial accounts
        if (
          error?.error?.ErrorType !== APIErrors.ACCOUNT_UPLOAD_QUOTA_EXCEEDED ||
          account?.accountType?.toLowerCase() !== AccountResourceType.TRIAL.toLowerCase()
        ) {
          return EMPTY;
        }

        return this.store.select(fromIndexing.selectUploadingQuotaLimitReached).pipe(
          take(1),
          switchMap(quotaLoaded => {
            if (quotaLoaded) {
              return [UploadActions.updateAccountQuotaOnUploadFailure({ file: storeFile })];
            }

            return this.apiService.Account.getAccount(account.id, true).pipe(
              concatMap(accountResult => {
                this.handleAccountQuotaNotification(accountResult);
                this.trackService.track('account_effect.get_account_quota.success', {
                  category: EventCategory.ACCOUNTS
                });
                return [
                  AccountsActions.updateAccountQuota({ accountQuota: accountResult.quotaUsage }),
                  UploadActions.updateAccountQuotaOnUploadFailure({ file: storeFile })
                ];
              }),
              catchError(error => {
                this.trackService.track('account_effect.get_account_quota.failed', {
                  category: EventCategory.ACCOUNTS,
                  data: {
                    error: error
                  }
                });

                return [AccountsActions.updateAccountQuotaFailed()];
              })
            );
          })
        );
      })
    )
  );

  // getAccountSettings effect - get account settings
  public getAccountSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AccountsActions.loadAccountSettings),
      withLatestFrom(this.store.select(fromCore.selectAccountTokenPermission)),
      switchMap(([{ id }, permission]) => {
        if (permission === AccountPermission.RESTRICTED_VIEWER) {
          return EMPTY;
        }
        return this.apiService.Account.getAccountSettings(id).pipe(
          switchMap(settings => {
            return [AccountsActions.addAccountSettings({ settings })];
          }),
          catchError(err => {
            return EMPTY;
          })
        );
      })
    )
  );

  // updateAccountCelebSettings effect - update account settings
  public updateAccountCelebSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AccountsActions.updateAccountCelebSettings),
      withLatestFrom(this.store.select(fromCore.selectCurrentAccountId)),
      withLatestFrom(this.store.select(fromCore.getSelectedAccountSettings)),
      switchMap(([[{ includeCelebrityRecognition }, id], settings]) => {
        const updatedSettings = {
          ...settings,
          includeCelebrityRecognition: includeCelebrityRecognition
        };
        this.store.dispatch(AccountsActions.addAccountSettings({ settings: updatedSettings }));
        return this.apiService.Account.updateAccountSettings(id, updatedSettings, { permission: AccountPermission.OWNER }).pipe(
          switchMap(() => {
            return EMPTY;
          }),
          catchError(err => {
            this.customizationUtilsService.displayErrorToast(err, { AppSettingsSaveError: '' }, null, false);
            return [
              AccountsActions.addAccountSettings({ settings: { ...updatedSettings, includeCelebrityRecognition: !includeCelebrityRecognition } })
            ];
          })
        );
      })
    )
  );

  // deleteOwnAccount effect - delete user's account
  public deleteOwnAccount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AccountsActions.deleteOwnAccount),
      switchMap(({ id }) => {
        return this.apiService.Account.deleteAccount(id).pipe(
          switchMap(() => {
            this.logger.log('[AccountsEffects] Delete own account success');
            return [AccountsActions.deleteOwnAccountSuccess()];
          }),
          catchError(err => {
            this.logger.log('[AccountsEffects] Delete own account failed');
            this.settingsService.handleDeleteError(err);
            return [AccountsActions.updateAccountState({ error: true, saving: false })];
          })
        );
      })
    )
  );

  // recoverOwnAccount effect - delete user's account
  public recoverOwnAccount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AccountsActions.recoverOwnAccount),
      switchMap(({ id }) => {
        return this.apiService.Account.deleteAccount(id, true).pipe(
          switchMap(() => {
            this.logger.log('[AccountsEffects] Recover own account success');
            this.trackService.track('settings.recover_account.success', {
              category: EventCategory.SETTINGS
            });
            this.toastService.success(this.translateService.instant('SettingsDeleteAccountSuccess'), false);
            return [AccountsActions.recoverOwnAccountSuccess()];
          }),
          catchError(err => {
            this.trackService.track('settings.recover_account.failed', {
              category: EventCategory.SETTINGS,
              data: {
                error: err
              }
            });
            this.toastService.error(null, this.translateService.instant('SettingsDeleteAccountError'), false);
            this.logger.log('[AccountsEffects] Recover own account failed');
            return [AccountsActions.updateAccountState({ error: true, saving: false })];
          })
        );
      })
    )
  );

  // leaveAccount effect - remove contributor
  public leaveAccount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AccountsActions.leaveAccount),
      withLatestFrom(this.store.select(fromCore.getUserDetails)),
      switchMap(([{ id }, user]) => {
        return this.apiService.Account.removeMyself(id, {
          userId: user.id,
          accountId: id,
          permission: AccountPermission.MY_ACCESS_MANAGER
        }).pipe(
          switchMap(() => {
            this.logger.log('[AccountsEffects] Leave account success');
            this.localStorageService.removeItem('accountId');
            return [
              AccountsActions.deleteAccount({ id: id }),
              AccountsActions.selectAccount({}),
              AccountsActions.updateAccountState({ saving: false })
            ];
          }),
          catchError(err => {
            this.logger.log('[AccountsEffects] Leave account failed');
            this.settingsService.handleLeaveError(err);
            return [AccountsActions.updateAccountState({ error: true, saving: false })];
          })
        );
      })
    )
  );

  // updateMediaServiceAccount effect - update media services account details
  public updateMediaServiceAccount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AccountsActions.updateMediaServicesAccount),
      withLatestFrom(this.store.select(fromCore.selectedAccountContract)),
      switchMap(([{ amsUpdateConfig }, selectedAccount]) => {
        const newAccount = {
          ...selectedAccount,
          mediaServices: {
            ...selectedAccount.mediaServices,
            aadApplicationId: amsUpdateConfig.aadConnection.applicationId,
            resourceGroup: amsUpdateConfig.resourceGroup,
            subscriptionId: amsUpdateConfig.subscriptionId
          }
        };
        this.logger.log('[AccountsEffects] Update media services account in store');
        return this.apiService.Account.updateAccountMediaServices(selectedAccount.id, amsUpdateConfig).pipe(
          switchMap(() => {
            this.logger.log('[AccountsEffects] Update media services account success');
            return [AccountsActions.updateSelectedAccount({ account: newAccount })];
          }),
          catchError(err => {
            this.logger.log('[AccountsEffects] Update media services account failed');
            this.settingsService.updateAccountConnectionErrorHandler(err);
            return [AccountsActions.updateAccountState({ saving: false, error: true })];
          })
        );
      })
    )
  );

  public navigateToSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AccountsActions.navigateToSettings),
      withLatestFrom(this.store.select(fromCore.selectCurrentAccount)),
      switchMap(([{ tab }, currentAccount]) => {
        if (!currentAccount) {
          return EMPTY;
        }
        const path = VIRoutingMap.settings.path.replace(':accountId', currentAccount.id);

        return [
          RouterActions.Go({
            path: [path],
            removeParams: true
          })
        ];
      })
    )
  );

  public navigateToCustomization$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AccountsActions.navigateToCustomization),
      withLatestFrom(this.store.select(fromCore.selectCurrentAccount)),
      switchMap(([{ page }, currentAccount]) => {
        if (!currentAccount) {
          return EMPTY;
        }
        const path = `${VIRoutingMap.customization.path.replace(':accountId', currentAccount.id)}/${camelCase(page)}`;

        return [
          RouterActions.Go({
            path: [path],
            removeParams: true
          })
        ];
      })
    )
  );

  public loadArmAccounts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AccountsActions.loadArmAccounts),
      switchMap(() => {
        if (!this.featureSwitchService.featureSwitch(FeatureSwitch.UseCacheApi)) {
          return of({});
        }

        // first try and fetch from browser cache
        return this.cacheService.match(this.cacheService.resources.ArmAccounts, { maxAge: TimeInterval.DAY * 1 });
      }),
      switchMap((cacheResponse: ICacheResponse<AccountContractSlim[]> = {}) => {
        const { json, date } = cacheResponse;

        if (json?.length) {
          this.trackService.track('account_effect.get_cached_arm_accounts.success', {
            category: EventCategory.ACCOUNTS,
            data: {
              count: json.length
            }
          });

          // refresh cache in the background in case cache data is older than X minutes
          const refreshThreshold = TimeInterval.MINUTE * 10;
          const shouldRefreshData = new Date().getTime() - new Date(date).getTime() > refreshThreshold;

          if (shouldRefreshData) {
            this.store.dispatch(AccountsActions.refreshCachedArmAccounts());
          }

          return [json];
        }

        // if no cached accounts found, fetch from api and update cache
        return this.apiService.ArmAccount.getArmAccounts().pipe(
          map((armAccounts: AccountContractSlim[]) => {
            this.trackService.track('account_effect.get_arm_accounts.success', {
              category: EventCategory.ACCOUNTS,
              data: {
                count: armAccounts?.length
              }
            });

            if (this.featureSwitchService.featureSwitch(FeatureSwitch.UseCacheApi)) {
              this.cacheService.put(this.cacheService.resources.ArmAccounts, armAccounts);

              this.trackService.track('account_effect.set_cached_arm_accounts.success', {
                category: EventCategory.ACCOUNTS,
                data: {
                  count: armAccounts?.length
                }
              });
            }

            return armAccounts;
          }),
          catchError(error => {
            this.trackService.track('account_effect.get_arm_accounts.failed', {
              category: EventCategory.ACCOUNTS,
              data: {
                error
              }
            });

            this.store.dispatch(AccountsActions.loadArmAccountsFailed());
            return EMPTY;
          })
        );
      }),
      switchMap(armAccounts => {
        return this.store.select(fromCore.selectUIAccounts).pipe(
          take(1),
          withLatestFrom(this.store.select(fromCore.selectedAccountLoaded)),
          withLatestFrom(this.store.select(fromCore.getUserDetails)),
          switchMap(([[trialAccounts, isSelectedAccountLoaded], user]) => {
            const actionsList = [];
            const allAccounts = [...trialAccounts, ...armAccounts];

            actionsList.push(AccountsActions.loadArmAccountsSuccess({ accounts: armAccounts }));

            if (!allAccounts.length) {
              this.apiService.setApiBaseLocation(this.configService.Config.api.defaultRegion, envTypes.PAID);
              actionsList.push(AccountsActions.zeroAccountsLoaded());
            } else if (!isSelectedAccountLoaded && this.shouldSelectAccount(trialAccounts, user)) {
              // if no account is selected until now, it will select an account once arm accounts are loaded
              actionsList.push(AccountsActions.selectAccount({ id: allAccounts[0].id }));
            }

            this.authService.AccountIds = allAccounts.map(a => a.id);

            return actionsList;
          })
        );
      })
    )
  );

  public refreshCachedArmAccounts$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AccountsActions.refreshCachedArmAccounts),
        switchMap(_ => {
          return this.apiService.ArmAccount.getArmAccounts().pipe(
            map((armAccounts: AccountContractSlim[]) => {
              this.cacheService.put(this.cacheService.resources.ArmAccounts, armAccounts);

              this.trackService.track('account_effect.refresh_cached_arm_accounts.success', {
                category: EventCategory.ACCOUNTS,
                data: {
                  count: armAccounts?.length
                }
              });
            }),
            catchError(error => {
              this.trackService.track('account_effect.refresh_cached_arm_accounts.failed', {
                category: EventCategory.ACCOUNTS,
                data: {
                  error
                }
              });
              return EMPTY;
            })
          );
        })
      ),
    {
      dispatch: false
    }
  );

  // getSelectedArmAccountDetails effect - gets the expanded details for the selected arm account.
  // called on select account change
  public getSelectedArmAccountDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AccountsActions.selectArmAccount),
      withLatestFrom(this.store.select(fromCore.selectCurrentAccount)),
      withLatestFrom(this.store.select(fromCore.getTenantId)),
      switchMap(([[{ id }, selectCurrentAccount], tenantId]) => {
        if (!this.featureSwitchService.featureSwitch(FeatureSwitch.ARM)) {
          return EMPTY;
        }

        const location = getARMLocation(selectCurrentAccount, this.configService);
        this.apiService.setApiBaseLocation(location, envTypes.PAID);

        this.store.dispatch(AccountsActions.selectArmAccountProcessing());
        this.store.dispatch(AccountsActions.selectArmAccountSuccess());

        this.logger.log(`[AccountsEffects] get selected arm account ${id}`);
        this.trackService.track('account_effect.select_arm_account', {
          category: EventCategory.ACCOUNTS
        });
        return this.apiService.ArmAccount.getArmAccount(
          selectCurrentAccount.subscriptionId,
          selectCurrentAccount.resourceGroupName,
          selectCurrentAccount.name,
          {
            accountId: id,
            location: location
          }
        ).pipe(
          takeUntil(this.actions$.pipe(ofType(AccountsActions.selectAccount))),
          withLatestFrom(this.store.select(fromCore.isArmAmslessAccount)),
          switchMap(([res, isArmAmslessAccount]) => {
            this.logger.log('[AccountsEffects] get selected arm account success');
            const armAccountCacheData: IArmAccountCacheData = {
              subscriptionId: res.subscriptionId,
              resourceGroupName: res.resourceGroupName,
              name: res.name,
              accountId: id,
              location: location
            };
            this.localStorageService.setItem(getLastArmStorageKey(tenantId), JSON.stringify(armAccountCacheData));
            this.localStorageService.setItem(StorageCacheKey.LastAccountType, AccountResourceType.ARM);

            this.commonTrackingDataService.accountType = res?.resourceType;
            this.commonTrackingDataService.isAmslessAccount = isArmAmslessAccount;

            if (res?.isPaid && res?.mediaServices && !res?.mediaServices.connected) {
              const resource = { StripAmsAddError: '' };
              this.translateService.translateResources(resource);
              const stripData: IStripData = {
                text: resource.StripAmsAddError,
                iconClass: 'i-vi-error',
                show: true,
                messageType: MessageType.ERROR,
                linkCallback: this.stripCallback.bind(this),
                trackingName: 'media.connection.error'
              };
              this.mediaConnectionStripId = this.stripService.trigger(stripData);
            }
            this.trackService.track('account_effect.select_arm_account.success', {
              category: EventCategory.ACCOUNTS
            });

            const actions = [];

            // load languages models - to check if account has training models
            actions.push(AccountsActions.addSelectedAccount({ account: res }));
            actions.push(LanguageModelActions.loadLanguageModels());
            actions.push(AccountsActions.loadAccountSettings({ id: res.id }));

            const isAutoDetectFeatureEnable = this.featureSwitchService.featureSwitch(FeatureSwitch.AutoDetectLanguage);
            if (isAutoDetectFeatureEnable) {
              actions.push(LanguageIdActions.loadDefaultLanguages());
              actions.push(LanguageIdActions.loadSelectedLanguages());
            }

            return actions;
          }),
          catchError(error => {
            this.commonTrackingDataService.accountType = '';
            this.logger.error('[AccountsEffects] getSelectedArmAccountDetails failed', error);
            this.trackService.track('account_effect.select_arm_account.failed', {
              category: EventCategory.ACCOUNTS,
              error: JSON.stringify(error)
            });
            return [
              AccountsActions.addSelectedAccountFailed({
                errorType: error?.error?.ErrorType
              })
            ];
          })
        );
      })
    )
  );

  // getSelectedArmAccountDetails effect - gets the expanded details for the selected arm account.
  // called on select account change
  public getLastArmAccountDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AccountsActions.loadLastArmAccount),
      withLatestFrom(this.store.select(fromCore.getTenantId)),
      switchMap(([, tenantId]) => {
        if (!this.featureSwitchService.featureSwitch(FeatureSwitch.ARM)) {
          return EMPTY;
        }

        const armAccountCache: IArmAccountCacheData = JSON.parse(this.localStorageService.getItem(getLastArmStorageKey(tenantId)));

        this.logger.log(
          // eslint-disable-next-line max-len
          `[AccountsEffects] get last selected arm account ${armAccountCache?.subscriptionId}/${armAccountCache?.resourceGroupName}/${armAccountCache?.name}`
        );
        this.trackService.track('account_effect.select_last_arm_account', {
          category: EventCategory.ACCOUNTS
        });

        return this.apiService.ArmAccount.getArmAccount(armAccountCache.subscriptionId, armAccountCache.resourceGroupName, armAccountCache.name, {
          accountId: armAccountCache.accountId,
          location: armAccountCache.location
        }).pipe(
          switchMap((res: Microsoft.VideoIndexer.Contracts.AccountContract) => {
            const location = getARMLocation(res, this.configService);
            this.apiService.setApiBaseLocation(location, envTypes.PAID);
            this.logger.log('[AccountsEffects] get last selected arm account success');
            this.authService.UserAccount = res;

            const actions = [];

            // TODO : load language/speech can be failed for last account which is restricted viewer.
            // We decided to leave it as is. should be solved according to the amount of the failures

            actions.push(AccountsActions.addSelectedArmAccount({ account: res }));
            actions.push(AccountsActions.selectArmAccountSuccess());
            actions.push(LanguageModelActions.loadLanguageModels());
            actions.push(AccountsActions.loadAccountSettings({ id: res.id }));

            const isAutoDetectFeatureEnable = this.featureSwitchService.featureSwitch(FeatureSwitch.AutoDetectLanguage);
            if (isAutoDetectFeatureEnable) {
              actions.push(LanguageIdActions.loadDefaultLanguages());
              actions.push(LanguageIdActions.loadSelectedLanguages());
            }

            return actions;
          }),
          catchError((error: HttpErrorResponse) => {
            this.commonTrackingDataService.accountType = '';
            this.logger.error('[AccountsEffects] getSelectedArmAccountDetails failed', error);
            this.trackService.track('account_effect.select_arm_account.failed', {
              category: EventCategory.ACCOUNTS,
              error: JSON.stringify(error)
            });

            // in case of error, wait for the arm accounts to be loaded and select the default account
            return this.store.select(fromCore.armAccountsLoaded).pipe(
              skipWhile(isArmAccountsLoaded => !isArmAccountsLoaded),
              switchMap(() => {
                const actions = [];
                // select different account when the error with 404 status (not found)
                if (error?.status === 404) {
                  actions.push(AccountsActions.selectAccount({ id: this.authService.defaultAccountId }));
                } else {
                  actions.push(AccountsActions.addSelectedArmAccountFailed({ errorType: error?.error?.ErrorType }));
                }
                return actions;
              })
            );
          })
        );
      })
    )
  );

  public checkGalleryProjectTabAccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AccountsActions.checkGalleryProjectTabAccess),
      switchMap(() => {
        return this.store.select(fromCore.selectedAccountLoaded).pipe(
          filter(loaded => loaded),
          combineLatestWith(this.store.select(fromCore.selectAccountTokenPermission)),
          filter(([, permission]) => permission !== null),
          take(1),
          switchMap(([, permission]) => {
            if (permission === AccountPermission.RESTRICTED_VIEWER) {
              return [
                AccountsActions.accountHasRestrictedAccess(),
                RouterActions.Go({
                  path: [`/${VIRoutingMap.mediaGallery.path}/${VIRoutingMap.galleryLibrary.path}`],
                  extras: null,
                  queryParams: null,
                  removeParams: true
                })
              ];
            }
            return EMPTY;
          })
        );
      })
    )
  );

  public acceptUseFaces$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AccountsActions.acceptUseOfFacialIdentification),
      withLatestFrom(this.store.select(fromCore.selectCurrentAccount)),
      switchMap(([{ signature, useCase }, account]) => {
        return this.apiService.Account.updateFaceGateFeatures(account.id, signature, useCase).pipe(
          switchMap(limitedAccessFeatures => {
            this.faceGatePopupService.onUpdateFaceFeaturesSuccess();
            return [AccountsActions.updateFaceGateFeaturesSuccess({ limitedAccessFeatures })];
          }),
          catchError(error => {
            this.faceGatePopupService.onUpdateFaceFeaturesFailed(error);
            return [AccountsActions.updateFaceGateFeaturesFailed({ error })];
          })
        );
      })
    )
  );

  public faceGatingPopUp$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AccountsActions.addSelectedAccount),
        mergeWith(this.actions$.pipe(ofType(AccountsActions.addSelectedArmAccount))),
        withLatestFrom(this.store.select(fromCore.selectAccountTokenPermission)),
        withLatestFrom(this.store.select(fromCore.getUserDetails)),
        withLatestFrom(this.store.select(fromCore.isAccountLimitedWithFaces)),
        tap(([[[{ account }, permission], user], isAccountFaceGated]) => {
          this.commonTrackingDataService.isAccountFaceGated = isAccountFaceGated;
          if (this.faceGatePopupService.shouldAutoShow(account, permission, user.signedInUserEmail)) {
            this.faceGatePopupService.autoShowPopup(account, user.signedInUserEmail);
          }
        })
      ),
    {
      dispatch: false
    }
  );

  public accountUnavailablePopUp$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AccountsActions.addSelectedAccount),
        mergeWith(this.actions$.pipe(ofType(AccountsActions.addSelectedArmAccount))),
        withLatestFrom(this.store.select(fromCore.isArmAmslessAccount)),
        filter(([, isArmAmsless]) => !isArmAmsless),
        tap(([{ account }]) => {
          this.handleAccountUnavailableDialog(account);
        })
      ),
    {
      dispatch: false
    }
  );

  public openAIRestrictionBanner = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AccountsActions.addSelectedAccount),
        mergeWith(this.actions$.pipe(ofType(AccountsActions.addSelectedArmAccount))),
        withLatestFrom(this.store.select(fromCore.isConnectedToOpenAI)),
        filter(([, isConnectedToOpenAI]) => isConnectedToOpenAI),
        tap(([{}]) => {
          const openAIMessageDeadline = new Date(2024, 9, 30);
          if (!hasDatePassed(new Date(), openAIMessageDeadline)) {
            const stripData: IStripData = {
              text: this.translateService.instant('OpenAIRestrictionBanner'),
              iconClass: NotificationIcon.Warning,
              show: true,
              messageType: MessageType.WARNING,
              trackingName: 'openAI.restriction.warning'
            };
            this.openAIStripId = this.stripService.trigger(stripData);
          }
        })
      ),
    {
      dispatch: false
    }
  );

  private settingsService: SettingsService;
  private mediaConnectionStripId: number;
  private openAIStripId: number;

  constructor(
    private logger: LoggerService,
    private actions$: Actions,
    private authService: AuthService,
    private apiService: ApiService,
    private store: Store<IState>,
    private trackService: TrackService,
    private localStorageService: LocalStorageService,
    private commonTrackingDataService: CommonTrackingDataService,
    private injector: Injector,
    private stripService: StripService,
    private translateService: TranslateHelperService,
    private featureSwitchService: FeatureSwitchService,
    private configService: ConfigService,
    private customizationUtilsService: CustomizationUtilsService,
    private faceGatePopupService: FaceGatePopupService,
    private edgeExtensionsStore: EdgeExtensionsStoreService,
    private notificationService: NotificationsService,
    private navigationService: AppNavigationService,
    private coreStore: CoreStoreService,
    private toastService: ToastService,
    private cacheService: CacheStorageService,
    private dialogService: DialogService,
    private utilsService: UtilsService
  ) {
    setTimeout(() => {
      this.initSettingsService();
    }, TRANSLATION_DELAY);
    this.initAccountPermission();
    this.initTrackingSelectedAccountId();
  }

  private initSettingsService() {
    this.settingsService = this.injector.get(SettingsService);
  }

  private shouldSelectAccount(trialAccounts: AccountContractSlim[], user: Microsoft.VideoIndexer.Contracts.UserContractSlim): boolean {
    const lastAccountType = this.localStorageService.getItem(StorageCacheKey.LastAccountType);
    const lastUserEmail = this.localStorageService.getItem(StorageCacheKey.LoginHint);
    const armAccountCache: IArmAccountCacheData = JSON.parse(this.localStorageService.getItem(getLastArmStorageKey(user.tenantId)));

    const isLastSelectedArmAccount =
      lastAccountType === AccountResourceType.ARM && lastUserEmail === user.email && armAccountCache?.location && armAccountCache?.accountId;

    const isDefaultTrialAccountAlreadySelected = trialAccounts?.length > 0 && lastAccountType !== AccountResourceType.ARM;

    // If the last selected account was an ARM account, or if a default trial account has already been selected,
    // then no account should be selected at this point.
    return !isLastSelectedArmAccount && !isDefaultTrialAccountAlreadySelected;
  }

  private handleAccountUnavailableDialog(account: Microsoft.VideoIndexer.Contracts.AccountContract) {
    this.logger.log(`[AccountsEffects] handleAccountUnavailableDialog`);
    // If the account is not Amsless / trial - show a dialog that account is unavailable
    if (account?.accountType?.toLowerCase() === AccountResourceType.TRIAL.toLowerCase()) {
      return;
    }

    const isClassic = !!account?.mediaServices;

    const dialogRef = this.dialogService.openDialog(
      {
        class: 'account-unavailable-dialog',
        component: AccountUnavailableDialogComponent,
        title: this.translateService.instant('accountUnavailableDialog_Header'),
        primaryButton: !isClassic
          ? {
              type: ActionButtonType.PRIMARY,
              action: {
                title: this.translateService.instant('accountUnavailableDialog_UpdateAccount'),
                value: null,
                id: 'update-account',
                type: UIActionType.UPDATE
              }
            }
          : undefined,
        secondaryButton: {
          type: ActionButtonType.SECONDARY,
          action: { title: this.translateService.instant('Close'), value: null, id: 'close' }
        },
        componentData: {
          isClassic: isClassic
        }
      },
      '500px',
      '255px',
      'account-unavailable-dialog-container',
      false
    );

    dialogRef.componentInstance.actionChange.pipe(take(1)).subscribe((event: IDialogEvent) => {
      if (event.action.type === UIActionType.UPDATE) {
        const url = this.utilsService.getUpdateAccountPortalUrl(account?.tenantId, account?.resourceId);
        // on error the url will be redirected to ibiza home page
        window.open(url, '_blank');
      }
    });
  }

  private handleTrialAccountAmslessNotification(account: Microsoft.VideoIndexer.Contracts.AccountContract) {
    if (
      !this.featureSwitchService.featureSwitch(FeatureSwitch.Amsless) ||
      account?.accountType?.toLowerCase() !== AccountResourceType.TRIAL.toLowerCase()
    ) {
      return;
    }

    const createTime = new Date(account?.createTime);
    const isAccountCreatedAmsless = createTime.getTime() > TRIAL_AMSLESS_DATE.getTime();
    if (!isAccountCreatedAmsless) {
      const notification = {
        id: `trial-account-amsless`,
        messageType: NotificationMessageType.Custom,
        startDate: new Date(),
        endDate: new Date(),
        type: NotificationType.Banner,
        level: NotificationLevel.Info,
        icon: NotificationIcon.Info,
        title: this.translateService.instant('TrialAccountUpdatedNotificationTitle'),
        toasted: false,
        text: this.translateService.instant('TrialAccountUpdatedNotificationText')
      };

      this.notificationService.notify(notification);
    }
  }

  private handleAccountQuotaNotification(account: Microsoft.VideoIndexer.Contracts.AccountContract) {
    if (account?.accountType?.toLowerCase() !== AccountResourceType.TRIAL.toLowerCase()) {
      return;
    }

    const quota = account.quotaUsage;
    const accountId = account.id;
    const quotaErrors = [
      {
        condition: () => isAccountDurationQuotaExceeded(quota),
        title: 'AccountDurationQuotaError',
        text: 'UploadFileAccountDurationQuotaError'
      },
      {
        condition: () => isAccountDailyCountQuotaExceeded(quota),
        title: 'AccountDailyCountQuotaError',
        text: 'UploadFileAccountDailyCountQuotaError'
      },
      {
        condition: () => isAccountDailyDurationQuotaExceeded(quota),
        title: 'AccountDailyDurationQuotaError',
        text: 'UploadFileAccountDailyDurationQuotaError'
      }
    ];
    const quotaError = quotaErrors.find(error => error.condition());

    if (quotaError) {
      const notification: INotification = this.getQuotaErrorMessageNotification(accountId, quotaError);
      this.notificationService.notify(notification);
    }
  }

  private getQuotaErrorMessageNotification(accountId: string, quotaError: { condition: () => boolean; title: string; text: string }): INotification {
    return {
      id: `${accountId}-quotaError`,
      messageType: NotificationMessageType.Custom,
      startDate: new Date(),
      endDate: new Date(),
      type: NotificationType.Alert,
      level: NotificationLevel.Warning,
      icon: NotificationIcon.Warning,
      title: this.translateService.instant(quotaError.title),
      toasted: true,
      link: {
        text: this.translateService.instant('AccountQuotaErrorCreateArmAccountLink'),
        callback: () => {
          this.navigationService.createAccountSubject.next(NavigationState.OPEN);
        }
      },
      text: this.translateService.instant(quotaError.text)
    };
  }

  private initAccountPermission() {
    this.authService.accountAccessTokenPermission$.subscribe(permission => {
      this.store.dispatch(AccountsActions.loadAccountAccessTokenPermission({ permission }));
      this.commonTrackingDataService.accountPermission = permission;
    });
  }

  private stripCallback($event) {
    this.store.dispatch(AccountsActions.navigateToSettings({}));
  }

  private initTrackingSelectedAccountId() {
    this.coreStore.selectedAccountId$.subscribe(accountId => {
      this.commonTrackingDataService.accountId = accountId;
    });
  }
}
