export interface IUpdatePaidAccountFormParams {
  subscriptionId: string;
  resourceGroupName: string;
  applicationId: string;
  applicationKey: string;
}

export enum AMSErrors {
  AMS_UNREACHABLE = 'AMS_UNREACHABLE',
  AMS_INVALID_AAD_CONNECTION = 'AMS_INVALID_AAD_CONNECTION',
  AMS_AAD_INVALID_APPLICATION_KEY = 'AMS_AAD_INVALID_APPLICATION_KEY',
  AMS_AAD_APPLICATION_NOT_IN_TENANT = 'AMS_AAD_APPLICATION_NOT_IN_TENANT',
  INVALID_INPUT = 'INVALID_INPUT',
  GENERAL_ERROR = 'GENERAL_ERROR',
  WRONG_CREDENTIALS = 'WRONG_CREDENTIALS'
}

export interface IUpdatePaidAccountError {
  message: string;
  type: AMSErrors;
}

export interface IConfigureConnectionData {
  id: string;
}
