import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { MatDialogRef } from '@angular/material/dialog';

import { Store } from '@ngrx/store';

import { take } from 'rxjs/operators';

import { upperFirst, camelCase } from 'lodash-es';

import { Errors, APIErrors } from '@common/modules/core/services/toast/errors';
import { ToastService } from '@common/modules/core/services/toast/toast.service';
import { LoggerService } from '@common/modules/core/services/logger/logger.service';
import { ErrorHandlerService } from '@common/modules/core/services/error/error-handler.service';
import { TranslateHelperService } from '@common/modules/translation/services/translate-helper.service';
import { IDialogData } from '@common/modules/shared/components/dialog/interfaces';
import { ActionButtonType } from '@common/modules/shared/components/action-button/interfaces';
import { DialogComponent } from '@common/modules/shared/components/dialog/dialog.component';
import { DialogService } from '@common/modules/shared/components/dialog/dialog.service';
import { EventCategory, TrackService } from '@common/modules/core/services/track';
import { envTypes } from '@common/modules/api/interfaces';

import { resources } from '../pages/settings/resources';
import * as fromAccounts from '../../core/reducers/accounts.reducer';
import * as accountsSelectors from '../../core/selectors/accounts.selectors';
import * as AccountsActions from '../../core/actions/accounts.actions';
import { IUpdatePaidAccountError, AMSErrors, IConfigureConnectionData } from '../components/configure-connection/interfaces';
import { ConfigureConnectionComponent } from '../components/configure-connection/configure-connection.component';
import { okAction } from '../components/account-settings/actions';
import { IDeleteAccount, IDeleteAccountOutput } from '../components/delete-account/interfaces';
import { DeleteAccountComponent } from '../components/delete-account/delete-account.component';
import { VIRoutingMap } from '../../app/routing/routes';
import { CoreStoreService } from '../../core/services/core-store.service';

import AccountContract = Microsoft.VideoIndexer.Contracts.AccountContract;

@Injectable({
  providedIn: 'root'
})
export class SettingsService {
  public resources = resources;
  public configurationError: IUpdatePaidAccountError;
  public okAction = okAction;

  private dialogRef: MatDialogRef<DialogComponent>;

  constructor(
    private logger: LoggerService,
    private toastService: ToastService,
    private errorHandler: ErrorHandlerService,
    private readonly store: Store<fromAccounts.IState>,
    private translate: TranslateHelperService,
    private dialogService: DialogService,
    private trackService: TrackService,
    private coreStore: CoreStoreService
  ) {}

  public updateAccount(account: AccountContract, newAccount: AccountContract) {
    this.store.dispatch(
      AccountsActions.updateAccountContract({
        account: account,
        newAccount: newAccount
      })
    );
  }

  public deleteAccount(accountId: string) {
    this.store.dispatch(AccountsActions.deleteOwnAccount({ id: accountId }));
  }

  public leaveAccount(accountId: string) {
    this.store.dispatch(AccountsActions.leaveAccount({ id: accountId }));
  }

  public updateAccountMediaServices(amsUpdateConfig: Microsoft.VideoIndexer.Contracts.UpdateAmsConnectionContract) {
    this.store.dispatch(AccountsActions.updateMediaServicesAccount({ amsUpdateConfig: amsUpdateConfig }));
  }

  public cancelDeleteAccount(accountId) {
    this.store.dispatch(AccountsActions.recoverOwnAccount({ id: accountId }));
  }

  public get selectAccountSaving$() {
    return this.store.select(accountsSelectors.accountSaving);
  }

  public get isAccountDeleteInProgress$() {
    return this.store.select(accountsSelectors.isAccountDeleteInProgress);
  }

  public get selectAccountError$() {
    return this.store.select(accountsSelectors.accountError);
  }

  public handleUpdateAccount(success: boolean, err?: HttpErrorResponse) {
    // Success
    if (success) {
      this.toastService.success(this.resources.SettingsUpdateAccountSuccess, false);
      return;
    }
    // Fail
    switch (err.error.ErrorType) {
      case Errors[APIErrors.INVALID_INPUT].key:
        this.toastService.error(err, this.resources.SettingsUpdateAccountNameError);
        break;
      case Errors[APIErrors.ACCESS_TOKEN_VALIDATION_FAILED].key:
        this.toastService.error(err, this.resources.ErrorTypes_ACCESS_TOKEN_VALIDATION_FAILED);
        break;
      case Errors[APIErrors.GENERAL].key:
        this.toastService.error(err, this.resources.ErrorTypes_GENERAL);
        break;
      default:
        this.errorHandler.handler(this.resources.SettingsUpdateAccountError);
    }
  }

  public toastJoinAccountSuccess(accountId: string) {
    this.translate
      .translateResources(resources, { accountId: accountId })
      .pipe(take(1))
      .subscribe(() => {
        this.logger.log('[SettingsService] toastDeleteSuccess');
        this.toastService.success(this.resources.UserInvitedToAccount, false);
      });
  }

  public toastJoinAccountError(accountId: string) {
    this.translate
      .translateResources(resources, { accountId: accountId })
      .pipe(take(1))
      .subscribe(() => {
        this.logger.log('[SettingsService] toastDeleteError');
        this.toastService.error(null, this.resources.UserNotInvitedToAccount, false);
      });
  }

  public updateAccountConnectionErrorHandler(err: HttpErrorResponse) {
    this.configurationError = {
      message: 'General Error',
      type: AMSErrors.GENERAL_ERROR
    };
    if (err && err.error && err.error.ErrorType) {
      switch (err.error.ErrorType) {
        case AMSErrors.AMS_UNREACHABLE:
        case AMSErrors.AMS_INVALID_AAD_CONNECTION:
        case AMSErrors.AMS_AAD_INVALID_APPLICATION_KEY:
        case AMSErrors.AMS_AAD_APPLICATION_NOT_IN_TENANT:
        case AMSErrors.INVALID_INPUT:
          const messageResource = `ConfigureConnectionError${upperFirst(camelCase(err.error.ErrorType))}`;
          const amsError: AMSErrors = <AMSErrors>err.error.ErrorType;

          this.configurationError = {
            message: messageResource,
            type: amsError
          };
          break;
        default:
          // General errors
          if (err.status === 400) {
            // Wrong Credentials
            this.configurationError = {
              message: `ConfigureConnectionError${upperFirst(camelCase(AMSErrors.WRONG_CREDENTIALS))}`,
              type: AMSErrors.WRONG_CREDENTIALS
            };
          }
      }
    }
  }

  public handleDeleteError(err) {
    if (err?.error?.ErrorType === APIErrors.USER_NOT_ADMIN) {
      this.toastService.error(err, this.resources.ErrorTypes_USER_NOT_ADMIN, false);
    } else {
      this.toastService.error(err, this.resources.DeleteAccountDeleteAccountError, false);
    }
  }

  public handleLeaveError(err) {
    this.toastService.error(err, this.resources.DeleteAccountPullOutFailed, false);
  }

  public openConfigureConnectionDialog(account: AccountContract) {
    const componentData: IConfigureConnectionData = {
      id: account.id
    };
    const data: IDialogData = {
      class: 'configure-connection',
      title: resources.ConfigureConnectionDialogHeader,
      component: ConfigureConnectionComponent,
      componentData: componentData
    };
    this.dialogRef = this.dialogService.openDialog(data, '660px');
    // Listen to dialog - on close.
    this.dialogRef.componentInstance.actionChange.subscribe(res => {
      if (res.dialogEventData?.success) {
        this.openConfigureConnectionSuccessDialog();
      }
    });

    this.trackService.track('settings.account.open_configure_connection_dialog', {
      category: EventCategory.SETTINGS
    });
  }

  public openDeleteAccountDialog(account: AccountContract, isUserOwner: boolean) {
    // Delete account component input
    const data: IDeleteAccount = {
      id: account.id,
      account: account,
      isUserOwner: isUserOwner
    };

    // Dialog title
    const title = isUserOwner ? `${resources.DeleteAccountDelete} ${account.name}` : resources.DeleteAccountDialogOptionsHeader;

    // Dialog data
    const deleteAccountDialogData: IDialogData = {
      class: 'delete-account-dialog',
      title: title,
      component: DeleteAccountComponent,
      componentData: data
    };
    this.dialogRef = this.dialogService.openDialog(deleteAccountDialogData, '480px');
    // Listen to dialog - on close.
    this.dialogRef.componentInstance.actionChange.subscribe(res => {
      this.onCloseDeleteDialog(res);
    });

    this.trackService.track('settings.account.open_delete_account_dialog', {
      category: EventCategory.SETTINGS
    });
  }

  public isPaidAccount(account: AccountContract): boolean {
    return account?.accountType?.toLowerCase() === envTypes.PAID.toLowerCase();
  }

  private openConfigureConnectionSuccessDialog() {
    const data: IDialogData = {
      class: 'configure-connection',
      title: resources.ConfigureConnectionSuccessLabel,
      content: resources.ConfigureConnectionSuccessContentLabel,
      primaryButton: {
        action: okAction,
        type: ActionButtonType.SECONDARY
      }
    };
    this.dialogService.openDialog(data, '460px', 'auto');
  }

  private onCloseDeleteDialog(res) {
    const data: IDeleteAccountOutput = res.dialogEventData;
    if (data && data.closeParentComponent) {
      this.coreStore.navigate([`/${VIRoutingMap.mediaGallery.path}/${VIRoutingMap.galleryLibrary.path}`]);
      this.trackService.track('settings.account.closed', {
        category: EventCategory.SETTINGS
      });
    }

    this.trackService.track('settings.account.close_delete_account_dialog', {
      category: EventCategory.SETTINGS
    });
  }
}
