<form [ngClass]="{'in-process': inProcess}">
  <div>
    <span>{{ resources?.ConfigureConnectionDialogSubHeader }}</span>
    <a
      class="link"
      target="_blank"
      role="link"
      [href]="repairAccountLink"
      [title]="resources?.ConfigureConnectionDialogLearnMore"
      [attr.aria-label]="resources?.ConfigureConnectionDialogLearnMore">
      {{ resources?.ConfigureConnectionDialogLearnMore }}
    </a>
  </div>
  <!-- Subscription Id -->
  <label for="SubscriptionId"
    >{{ resources?.ConfigureConnectionDialogSubscriptionID }}
    <i role="none" class="mandatory">*</i>
  </label>
  <input
    id="SubscriptionId"
    type="text"
    autocomplete="off"
    (input)="checkAllowConnect()"
    [(ngModel)]="configureConnectionParams.subscriptionId"
    [ngModelOptions]="{standalone: true}"
    [placeholder]="resources?.BuyWizardManualSubscriptionIdPlaceholder"
    [title]="resources?.BuyWizardManualSubscriptionIdPlaceholder"
    [attr.aria-label]="resources?.BuyWizardManualSubscriptionIdPlaceholder"
    [attr.disabled]="inProcess ? '' : null"
    [attr.aria-required]="true"
    [attr.tabindex]="0"/>
  <!-- Resource group name -->
  <label for="resourceGroupName"
    >{{ resources?.ConfigureConnectionDialogRGName }}
    <i role="none" class="mandatory">*</i>
  </label>
  <input
    id="resourceGroupName"
    type="text"
    autocomplete
    (input)="checkAllowConnect()"
    [(ngModel)]="configureConnectionParams.resourceGroupName"
    [ngModelOptions]="{standalone: true}"
    [placeholder]="resources?.BuyWizardManualResourceGroupNamePlaceholder"
    [title]="resources?.BuyWizardManualResourceGroupNamePlaceholder"
    [attr.aria-label]="resources?.BuyWizardManualResourceGroupNamePlaceholder"
    [attr.disabled]="inProcess ? '' : null"
    [attr.aria-required]="true"
    [attr.tabindex]="0"/>
  <!-- App Id -->
  <label for="applicationId"
    >{{ resources?.ConfigureConnectionDialogApplicationID }}
    <i role="none" class="mandatory">*</i>
  </label>
  <input
    id="applicationId"
    type="text"
    autocomplete="off"
    (input)="checkAllowConnect()"
    [(ngModel)]="configureConnectionParams.applicationId"
    [ngModelOptions]="{standalone: true}"
    [placeholder]="resources?.BuyWizardManualApplicationIDPlaceholder"
    [title]="resources?.BuyWizardManualApplicationIDPlaceholder"
    [attr.aria-label]="resources?.BuyWizardManualApplicationIDPlaceholder"
    [attr.disabled]="inProcess ? '' : null"
    [attr.aria-required]="true"
    [attr.tabindex]="0"/>
  <!-- AMS key -->
  <label for="applicationKey"
    >{{ resources?.ConfigureConnectionDialogApplicationKey }}
    <i role="none" class="mandatory">*</i>
  </label>
  <input
    id="applicationKey"
    type="password"
    autocomplete="off"
    (input)="onApplicationKeyInput()"
    [(ngModel)]="configureConnectionParams.applicationKey"
    [ngModelOptions]="{standalone: true}"
    [ngClass]="{error: isError && isApplicationKeyError()}"
    [placeholder]="resources?.BuyWizardManualApplicationKeyPlaceholder"
    [title]="resources?.BuyWizardManualApplicationKeyPlaceholder"
    [attr.aria-label]="resources?.BuyWizardManualApplicationKeyPlaceholder"
    [attr.disabled]="inProcess ? '' : null"
    [attr.aria-required]="true"
    [attr.tabindex]="0"/>
  <div *ngIf="isError">
    <span class="input-error" *ngIf="isApplicationKeyError()">{{
      currentError?.message
    }}</span>
    <span class="error-message" *ngIf="!isApplicationKeyError()">{{
      currentError?.message
    }}</span>
  </div>
</form>

<div class="bottom-form">
  <div class="loading" *ngIf="inProcess">
    <app-vi-spinner class="spinner" [size]="'tiny'"></app-vi-spinner>
    <span class="text">{{
      resources?.VIConfigureConnectionUpdatingAccountLabel
    }}</span>
  </div>
  <div class="buttons">
    <app-vi-action-button
      class="button"
      [action]="connectAction"
      [type]="ActionButtonType.PRIMARY"
      [isDisabled]="connectAction.isDisabled"
      (actionClick)="reconnectToAzure()">
    </app-vi-action-button>
    <app-vi-action-button
      class="button"
      [action]="cancelAction"
      [type]="ActionButtonType.SECONDARY"
      (actionClick)="close()">
    </app-vi-action-button>
  </div>
</div>
