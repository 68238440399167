export const resources = {
  ConfigureConnectionDialogSubHeader: '',
  ConfigureConnectionDialogSubscriptionID: '',
  BuyWizardManualSubscriptionIdPlaceholder: '',
  ConfigureConnectionDialogRGName: '',
  BuyWizardManualResourceGroupNamePlaceholder: '',
  ConfigureConnectionDialogApplicationID: '',
  BuyWizardManualApplicationIDPlaceholder: '',
  ConfigureConnectionDialogApplicationKey: '',
  BuyWizardManualApplicationKeyPlaceholder: '',
  ConfigureConnectionDialogConnect: '',
  Cancel: '',
  ConfigureConnectionDialogLearnMore: '',
  VIConfigureConnectionUpdatingAccountLabel: '',
  ConfigureConnectionErrorAmsUnreachable: '',
  ConfigureConnectionErrorAmsInvalidAadConnection: '',
  ConfigureConnectionErrorAmsAadInvalidApplicationKey: '',
  ConfigureConnectionErrorAmsAadApplicationNotInTenant: '',
  ConfigureConnectionErrorInvalidInput: '',
  ConfigureConnectionErrorWrongCredentials: '',
  ConfigureConnectionErrorGeneralError: ''
};
